const ForceNewTab = {

	init: function() {
		let body = document.getElementsByTagName('body')[0];
		let currentHost = window.location.host;

		let settings = {
			external: false,
			pdf: false,
		}

		if ( body.getAttribute('data-force-tab-external') ) {
			settings.external = true;
		}

		if ( body.getAttribute('data-force-tab-pdf') ) {		
			settings.pdf = true;
		}

		let links = document.getElementsByTagName('a');

			[...links].forEach( (link, i) => {

				let href = removeURLParameter( link.getAttribute('href') )

				if ( typeof href !== 'undefined' && href !== null && href.length > 0 ) {

					// BEGIN CHECK EXTERNAL LINK 
					if ( settings.external ) {
						// Skip relative path
						if ( /^(?:[a-z]+:)?\/\//i.test(href) && /^[#]/i ) {
							
							let url = new URL(href) // convert it into an URL
	
							// Check if URL is same with Current Host
							if ( url.host !== currentHost ) {
								link.setAttribute('target', '_blank');
								link.setAttribute('rel', 'noreferrer noopener')
							}
						}
					}

					// BEGIN CHECK PDF
					if ( settings.pdf ) {
						if ( /(\.pdf)+/.test( href ) ) {
							link.setAttribute('target', '_blank');
							link.setAttribute('rel', 'noreferrer noopener')
						}
					}

				}
			});

			function removeURLParameter(url) {
				if ( typeof(url) == 'string' && url != null && url != undefined ) {
					return url.split("?")[0];
				}
			}
	},
	
	resize: function() {

	},
}

export default ForceNewTab;