if( $('.spaghetti-table-to-not-table').length > 0 ) {
	// console.log('spaghetti table')
	$('.spaghetti-table-to-not-table').each( (i, table ) => {

		let tcolspan = $(table).find('td[colspan]') 

		$(tcolspan).each( (j, thead ) => {
			if ( parseInt( $(tcolspan).attr('colspan') ) > 1 ) {

				let tr = $(tcolspan).parent('tr')
				$(tr).addClass('head')

				$(table).addClass('spaghettied')

			}
		} )
	} )
}