import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-eventSpeakers:not([aicb-component-ready])`,
		parts: {
			slickWrapper: `.aicb-eventSpeakers-slick_wrapper`,
			slickCol: '.aicb-eventSpeakers-column',
			slickDots: '.aicb-eventSpeakers-slick_navigation-dots',
			arrows: '.aicb-eventSpeakers-slick_navigation-arrows',
			pagination: '.aicb-eventSpeakers-slick_navigation-pagination',
			paginationCurrent: '.aicb-eventSpeakers-slick_navigation-pagination .pagination-current',
			paginationMax: '.aicb-eventSpeakers-slick_navigation-pagination .pagination-max',
		}
	}
	const EventSpeakers = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const slidesToShow = {
			medium: 3,
			large: 4,
			xlarge: 5
		}
		const slickWrapper = element.querySelector( selectors.parts.slickWrapper );
		const slickCol = element.querySelector( selectors.parts.slickCol );
		const slickDots = element.querySelector( selectors.parts.slickDots );
		const arrows = element.querySelector( selectors.parts.arrows );
		const paginationCurrent = element.querySelector( selectors.parts.paginationCurrent );
		const paginationMax = element.querySelector( selectors.parts.paginationMax );
		const maxSlides = Array.from( slickWrapper.querySelectorAll(':scope > *') ).length;
		let slickInited = false;
		

		const slickOptions = {
			slidesToShow: 1.5,
			arrows: true,
			dots: true,
			autoplay: false,
			infinite: false,
			centerMode: true,
			mobileFirst: true,
			centerPadding: 0,
			draggable: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: mediaQuery.breakpoint.medium,
					settings: {
						slidesToShow: slidesToShow.medium,
						swipeToSlide: false,
						slidesToScroll: slidesToShow.medium,
						centerMode: maxSlides <= slidesToShow.medium ? true : false,
						arrows: true,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.large,
					settings: {
						slidesToShow: slidesToShow.large,
						swipeToSlide: false,
						slidesToScroll: slidesToShow.large,
						centerMode: maxSlides <= slidesToShow.large ? true : false,
						arrows: true,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.xlarge,
					settings: {
						slidesToShow: slidesToShow.xlarge,
						swipeToSlide: false,
						slidesToScroll: slidesToShow.xlarge,
						centerMode: maxSlides <= slidesToShow.xlarge ? true : false,
						arrows: true,
					}
				},
			]
		}
		
		const paginate = ( slick ) => {
			// console.log(`paginate`, slick )
			if ( typeof slick.$dots !== 'undefined' && slick.$dots !== null && slick.$dots.length > 0 ) {
				const slickDots = slick.$dots[0];
				const maxCount = Array.from( slickDots.querySelectorAll(':scope > li') ).length;
				const currentDot = Array.from( slickDots.querySelectorAll(':scope > li')).indexOf( slickDots.querySelector(':scope > .slick-active') ) || 0;
				paginationMax.innerHTML = maxCount;
				paginationCurrent.innerHTML = currentDot + 1;
			} else {
				paginationMax.innerHTML = 1;
				paginationCurrent.innerHTML = 1;
			}
		}

		const initSlick = () => {
			const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			// console.log(`viewportWidth`, viewportWidth, mediaQuery.breakpoint.xlarge, mediaQuery.breakpoint.large, )
			let minSlideToSlick = viewportWidth >= mediaQuery.breakpoint.xlarge ? slidesToShow.xlarge : viewportWidth >= mediaQuery.breakpoint.large ? slidesToShow.large : slidesToShow.medium;
			// console.log(`minSlideToSlick`, minSlideToSlick )

			if ( slickWrapper !== null && ! slickWrapper.classList.contains( 'slick-initialized' ) ) {
				$( slickWrapper ).slick( {
					...slickOptions,
					...{ appendArrows: arrows, appendDots: slickDots },
				})
			}
		}

		const initComponent = () => {
			$( slickWrapper ).on('init', ( e, slick ) => {
				paginate( slick )
			})
			$( slickWrapper ).on('breakpoint', ( e, slick ) => {
				paginate( slick )
			})
			$( slickWrapper ).on('afterChange', ( e, slick, currentSlide, nextSlide ) => {
				paginate( slick )
			})

			initSlick();

			window.addEventListener('resizeEnd', e => {
				initSlick( slickWrapper );
			} )
		}


		// Function calls here ====>
		initComponent();
	}


	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new EventSpeakers( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new EventSpeakers({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()