import $ from 'jquery';

import mediaQuery from '../util/aicb.mediaquery';

const ExpandBoxRight = {
	main: '.aicb-expandBoxRight',
	classes: {
		floatArea: '.aicb-expandBoxRight-float_area',
		floatBox: '.aicb-expandBoxRight-float_box',
		floatBoxContent: '.aicb-expandBoxRight-float_box-content',
		item: '.aicb-expandBoxRight-loop',
		expanded: '.aicb-expandBoxRight-loop-expand',
	},

	init: function() {
		var _this = this;

		$(`${this.main}`).each( (i, expandBoxRight) => {
			let component = $(expandBoxRight);
			let floatArea = component.find(`${this.classes.floatArea}`);
			let floatBox = component.find(`${this.classes.floatBox}`);
			let items = component.find(`${this.classes.item}`);
			/* 
				items.on( 'mouseover', function( e ) {
					_this._itemHover( component, floatArea, floatBox, e );
				});

				component.on( 'mouseleave', function( e ) {
					_this._itemOut( component, floatArea, floatBox, e );
				}); 
			*/

		})
	},
	
	resize: function() {
	},

	_itemHover: function(component, floatArea, floatBox, e) {
		if ( $(window).width() > mediaQuery.breakpoint.medium ) {
			// console.log( component, floatArea, floatBox, e );
			let content = $(e.currentTarget).find(`${this.classes.expanded}`).html();
			floatBox.find(`${this.classes.floatBoxContent}`).html(content);
			floatBox.addClass('ready');

			// calculate height
			let top = $(e.currentTarget).position().top + $(e.currentTarget).outerHeight(true);
			let height = floatBox.outerHeight(true);
			let maxHeight = $(document).height();
			let position = ( floatArea.offset().top + top + height > maxHeight ? floatArea.innerHeight() - height : top );
			floatBox.css( `top`, `calc(${position}px - 1.5rem)`);
		}
	},

	_itemOut: function(component, floatArea, floatBox, e) {
		floatBox.removeClass('ready');
	}
	


}

export default ExpandBoxRight;