'use strict';

import mediaQuery from './util/aicb.mediaquery';

const HeaderScroll = {
	init: function() {

	},
	resize: function() {

	},
	scroll: function(e) {
		if ( $(window).outerHeight > $(`#site-header`).outerHeight() ) {
			$(`#site-header`).addClass(`scrolled`);
		} else {
			$(`#site-header`).removeClass(`scrolled`);
		}
	}
}
export default HeaderScroll;