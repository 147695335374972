import $ from 'jquery';
import 'slick-carousel';
import Foundation from 'foundation-sites/js/foundation';
import mediaQuery from '../util/aicb.mediaquery';
import { debounce } from '../util/debounce';

( function(){
	const selectors = {
		self: `[data-aicb-entry-component="committeeTab"]:not([aicb-component-ready])`,
    tabList: `[data-aicb-committee-tab="tabList"]`,
    tabPanels: `[data-aicb-committee-tab="tabPanels"]`,
    tabPanel: `[data-aicb-committee-tab="tabPanel"]`,
    boxes: `[data-aicb-committee-tab="boxes"]`,
    box: `[data-aicb-committee-tab="box"]`
	}

  const CommitteeTab = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
    const tabFamily = [];

    const groupBoxes = ( tabPanel ) => {
      return new Promise ( ( resolve, reject) => {
        const boxGroup = [{ name: '',  boxItems: [], boxesWrapper: tabPanel.querySelector( selectors.boxes ) }];
        const boxes = Array.from( tabPanel.querySelectorAll( selectors.box ) );
        boxes.forEach( ( box => {
          const groupnName = typeof box.getAttribute('data-box-group') !== 'undefined' && box.getAttribute('data-box-group') !== null ? box.getAttribute('data-box-group') : '';
          if ( groupnName === '' ) {
            boxGroup[0].boxItems.push( box );
          } else {
            const foundGroup =  boxGroup.find( group => group.name === groupnName );
            if ( typeof foundGroup !== 'undefined' ) {
              foundGroup.boxItems.push( box )
            } else {
              boxGroup.push({
                name: groupnName,
                boxItems: [ box ],
                boxesWrapper: tabPanel.querySelector( selectors.boxes )
              })
            }

          }
        }))
        resolve( boxGroup )
      })
    }

    const groupBoxesElement = ( boxGroup ) => {
      return new Promise( ( resolve, reject ) => {
        boxGroup.forEach( ( box ) => {
          if ( box.boxItems.length > 0 ) {
            const boxGroupWrapper = document.createElement('div');
            boxGroupWrapper.classList.add('aicb-committee-tab-boxes-group');
            const boxGroupTitle = document.createElement('div');
            boxGroupTitle.classList.add('aicb-committee-tab-boxes-group-title');
            boxGroupTitle.innerHTML = `<h4 class="title-text">${ box.name }</h4>`
            boxGroupWrapper.appendChild( boxGroupTitle );
            const boxBoxesWrapper = document.createElement('div');
            boxBoxesWrapper.classList.add('aicb-committee-tab-boxes-group-boxes');
            if ( box.boxItems.length > 3 ) {
              boxBoxesWrapper.classList.add('left');
            }

            box.boxItems.forEach( boxItem => {
              boxBoxesWrapper.appendChild( boxItem )
            })

            boxGroupWrapper.appendChild( boxBoxesWrapper );

            if ( typeof box.boxesWrapper !== 'undefined' && box.boxesWrapper !== null ) {
              box.boxesWrapper.appendChild( boxGroupWrapper )
            }
          }
        })
        resolve();
      })
    }

    const getNextSibling = ( currentSibling ) => {
      const nextSibling = currentSibling.nextElementSibling;
      if ( typeof nextSibling === 'undefined' || nextSibling === null || nextSibling.getAttribute('data-aicb-entry-component') !== 'committeeTab' ) {
        return false;
      }
      return nextSibling;
    }

    const makeTabFamily = () => {
      return new Promise( (resolve, reject ) => {
        
        let currentSibling = element;
        while ((currentSibling = getNextSibling( currentSibling ))) {
          tabFamily.push( currentSibling )
        }
        resolve( tabFamily );
      })
    }

    const combineFamilyToTabs = () => {
      return new Promise( async (resolve, reject) => {
        const fragment = document.createDocumentFragment();

        const tabList = element.querySelector( selectors.tabList );
        const tabPanels = element.querySelector( selectors.tabPanels );
        const tabPanel = element.querySelector( selectors.tabPanel );
        
        // Create tab navigation for the first item
        const li = document.createElement('li');
        const a = document.createElement('a');
        li.classList.add('tabs-title', 'is-active');
        a.setAttribute('href', `#${ tabPanel.id }`);
        a.innerHTML = `${  tabPanel.getAttribute('data-aicb-committee-tab-title') }`;
        a.setAttribute('aria-selected', 'true');
        li.appendChild( a ); // Append a -> li
        tabList.appendChild( li ); // Append to the tabList
        tabPanel.classList.add('is-active');
        await groupBoxes( tabPanel ).then( boxGroup => { groupBoxesElement( boxGroup ) } );
        tabPanels.appendChild( tabPanel ); // Move the current tab panel into the tab panels
        
        // Move sibling tab panel into tab pannels
        tabFamily.forEach( async (sibling, i) => {
          const siblingTabPanel = sibling.querySelector( selectors.tabPanel );
          const tabId = siblingTabPanel.id;
          const tabTitle = siblingTabPanel.getAttribute(`data-aicb-committee-tab-title`);
          // Create tab navigation
          const tabLi = document.createElement('li');
          const tabA = document.createElement('a');
          tabLi.classList.add('tabs-title');
          tabA.setAttribute('href', `#${ tabId }`);
          tabA.innerHTML = tabTitle;
          // Append item into correct wrapper
          tabLi.appendChild( tabA )
          tabList.appendChild( tabLi );
          await groupBoxes( siblingTabPanel ).then( boxGroup => { groupBoxesElement( boxGroup ) } );
          tabPanels.appendChild( siblingTabPanel );
          // Remove empty shell
          sibling.remove();
        })
        resolve( tabList )
      })
      
    }

    const resizeBar = () => {
      const tabList = element.querySelector( selectors.tabList );
      if ( tabList !== null ) {
        tabList.style.removeProperty('--var-tabs-width');
        window.requestAnimationFrame( () => {
          const tabListWidth = tabList.getBoundingClientRect().width;
          const tabsWidth = Array.from( tabList.querySelectorAll(':scope > li') ).reduce( ( sum, li ) => li.getBoundingClientRect().width + sum, 0 )
          tabList.style.setProperty('--var-tabs-width', `${ Math.max( tabsWidth, tabListWidth )}px`);
        })
      }
    }
 

    const initComponent = async () => {
      const location = window.location;
      let noAnchor = true;
      if ( location.hash ) {
        if ( document.querySelector( location.hash ) !== null ) {
          noAnchor = false;
        } else {
          noAnchor = true
        }
      }

      
      await makeTabFamily();
      await combineFamilyToTabs().then( tabNavUl => {
        if ( window.location.hash !== '' && tabNavUl.querySelector(`[href="${ window.location.hash }"]`) !== null ) {
          setTimeout( () => {
            if ( ! noAnchor ) {
              tabNavUl.scrollIntoView({ behavior: "smooth", block: "start", inline: "center" })
            }
            resizeBar();
          }, 500 )
        }
      });
      window.addEventListener('resizeEnd', e => {
        resizeBar();
      })
		}

		// Function calls here ====>
		initComponent();
  }

  // Wait for loading to initiate component
	function onDocumentReady(){
    let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
      // Do not initalize if it was sibling of another
      if ( elements[i].previousElementSibling === null ||  elements[i].previousElementSibling.getAttribute('data-aicb-entry-component') !== 'committeeTab' ) {
        new CommitteeTab( elements[i] );
      }
		};
			
    // Mutation Observer to observe dynamically addeded component
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    new MutationObserver( function( mutations ) {
      mutations.forEach( function ( mutation ) {
        let nodesArray = [].slice.call(mutation.addedNodes);
        if (nodesArray.length > 0) {
          nodesArray.forEach(function (addedNode) {
            if (addedNode.querySelectorAll) {
              [].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
                if ( element.previousSibling === null ||  element.previousSibling.getAttribute('data-aicb-entry-component') !== 'committeeTab' ) {
                  new CommitteeTab( element );
                }
              });
            }
          });
        }
      } )
    } ).observe( document.querySelector('body'), {
      subtree: true,
      childList: true,
      characterData: true
    } )
	}
  if (document.readyState !== 'complete') {
    onDocumentReady();
  } else {
    window.addEventListener('load', () => {
      onDocumentReady();
    });
  }


} )()