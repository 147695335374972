'use strict';

import $ from 'jquery';

var isSafari = {

	safari: false,
	ios: false,

	init: function() {
		this.safari = !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/);
		this.ios = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
	}
}

export default isSafari;