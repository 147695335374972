'use strict';

import mediaQuery from './util/aicb.mediaquery';

const AccordionMenu = {

	init: function() {
	},

	resize: function() {
		this._accordion();
	},

	_accordion: function() {
		if ( $('.footer-menu.menu.accordion-menu').length > 0 ) {
			if ( $(window).width() >= mediaQuery.breakpoint.medium ) {
				$('.footer-menu.menu.accordion-menu').foundation('showAll');
			} else {
				$('.footer-menu.menu.accordion-menu').foundation('hideAll');
			}
		}
	}
}

export default AccordionMenu;
