import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-redactorContent > table:not([aicb-component-ready]), .aicb-redactorContent p > table:not([aicb-component-ready]), .aicb-redactorContent table.table-responsive, .aicb-redactorContent .table-responsive > table, .aicb-redactorContent table`,
	}
	const FixedTable = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		let initiated = false;
		let root = null;
		let wrapper = null;
		let fixedScrollbarParent = null;
		let fixedScrollbar = null;
		let fixedScrollbarInner = null;
		let fixedScrollbarThumb = null;	
		let isIntersect = false;
		const scrollElement = element.closest('.aicb-reveal') !== null ? element.closest('.aicb-reveal') : window;

		function throttle(f, delay) {
			let timer = 0;
			return function(...args) {
					clearTimeout(timer);
					timer = setTimeout(() => f.apply(this, args), delay);
			}
	}
		

		const isScrollable = function (ele) {
			// Compare the height to see if the element has scrollable content
			const hasScrollableContent = ele.scrollWidth > ele.clientWidth;
	
			// It's not enough because the element's `overflow-y` style can be set as
			// * `hidden`
			// * `hidden !important`
			// In those cases, the scrollbar isn't shown
			const overflowXStyle = window.getComputedStyle(ele).overflowX;
			const isOverflowHidden = overflowXStyle.indexOf('hidden') !== -1;
	
			return hasScrollableContent && !isOverflowHidden;
	};

		// Intersec observer callback
		const intersect = () => {
			fixedScrollbarParent.style.width = `${ wrapper.getBoundingClientRect().width }px`;
			fixedScrollbarThumb.style.width = `calc( ${ wrapper.clientWidth / wrapper.scrollWidth * 100 }% - 10px )`;

			if ( isScrollable( wrapper ) ) {
				wrapper.classList.add('scrollable')
			} else {
				wrapper.classList.remove('scrollable')
			}
			fixedScrollbarInner.style.width = `${ element.scrollWidth }px`;
		}

		const syncScroll = ( e ) => {
			fixedScrollbar.scrollLeft = wrapper.scrollLeft;
			fixedScrollbarThumb.style.left = `${ wrapper.scrollLeft / wrapper.scrollWidth * 100 }%`;
		}

		const syncScrollReversed = ( e ) => {
			wrapper.scrollLeft = fixedScrollbar.scrollLeft;
			fixedScrollbarThumb.style.left = `${ wrapper.scrollLeft / wrapper.scrollWidth * 100 }%`;
		}

		const stickyFixedScrollbar = () => {
			const rect = element.getBoundingClientRect();
			// console.log( fixedScrollbarParent.offsetHeight  )
			const scrollBarRect = fixedScrollbarParent.getBoundingClientRect();
			// const scrollLeft = window.pageXOffset || document.documentElement.scrollLeft;
    	// const scrollTop = window.pageYOffset || document.documentElement.scrollTop;
			fixedScrollbarParent.style.top = `${ Math.max( Math.min( rect.top + element.offsetHeight, window.innerHeight ), rect.top + fixedScrollbarParent.offsetHeight )}px`
		}

		const initComponent = () => {
			// Root
			root = document.createElement('div');
			root.classList.add('scrollable-root')
			if ( element.getAttribute('style') !== null ) {
				root.setAttribute( 'style', element.getAttribute('style')  )
				element.removeAttribute( 'style' );
			}
			element.parentNode.insertBefore( root, element );

			// Wrap table
			wrapper = document.createElement('div');
			wrapper.classList.add('table-scrollable')
			wrapper.appendChild( element );
			root.appendChild( wrapper )

			// Create and add fixed scrollbar
			fixedScrollbarParent = document.createElement('div');
			fixedScrollbarParent.classList.add('fixedScrollbarParent')

			fixedScrollbar = document.createElement('div');
			fixedScrollbar.classList.add('fixedScrollbar')

			fixedScrollbarInner = document.createElement('div');
			fixedScrollbarInner.classList.add('fixedScrollbarInner');

			fixedScrollbarThumb = document.createElement('div');
			fixedScrollbarThumb.classList.add('fixedScrollbarThumb')

			fixedScrollbar.appendChild(fixedScrollbarInner);
			fixedScrollbarParent.appendChild( fixedScrollbar );
			fixedScrollbarParent.appendChild( fixedScrollbarThumb );

			wrapper.appendChild(fixedScrollbarParent);

			setTimeout( () => {
				intersect();
				window.addEventListener('resizeEnd', e => {
					intersect();
					stickyFixedScrollbar();
				})
				if ( element.closest('.aicb-reveal') !== null ) {
					$( element.closest('.aicb-reveal') ).on('open.zf.reveal', function(e) {
						setTimeout( () => {
							intersect();
							stickyFixedScrollbar();
						}, 500 )
					});
				}

				scrollElement.addEventListener('scroll', e => {
					stickyFixedScrollbar( e );
				})
				wrapper.addEventListener('scroll', e => {
					syncScroll( e )
				})
				fixedScrollbar.addEventListener('scroll', e => {
					syncScrollReversed( e )
				})
			}, 1 )
		}

		const waitForParent = () => {
			const parentRedactor = element.closest('.aicb-redactorContent');
			if ( parentRedactor !== null ) {
				const rect = parentRedactor.getBoundingClientRect();
				if ( rect.width <= 0 ) {
					const resizeObserver = new ResizeObserver ( throttle( (entries) => {
						for ( const entry of entries ) {
							if ( entry.contentRect ) {
								const contentBoxSize = entry.contentRect.width;
								if ( contentBoxSize > 0 && ! initiated  ) {
									initiated = true;
									initComponent();
								}
							}
						}
					}, 500 ) ).observe( parentRedactor )
				} else {
					initiated = true;
					initComponent();
				}
			}
		}

		waitForParent();
	}


	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new FixedTable( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new FixedTable({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()


