import $ from 'jquery';

import mediaQuery from '../util/aicb.mediaquery';

const EmptySpace = {
	main: '.aicb-emptySpace',
	numberTest: RegExp( /(^\d*\.?\d*$)/, 'g'), // Fallback to 'px' if no unit stated

	init: function() {
	},
	
	resize: function() {
		
		$(`${this.main}`).each( ( i, emptySpace ) => {
			let space = $(emptySpace);
			if ( $(window).width() < mediaQuery.breakpoint.medium ) {
				space.css(`height`, this.numberTest.test( space.attr(`data-space-mobile`) ) ? `${space.attr(`data-space-mobile`)}px` : space.attr(`data-space-mobile`) );
			} else if ( $(window).width() < mediaQuery.breakpoint.large ) {
				space.css(`height`, this.numberTest.test( space.attr(`data-space-tablet`) ) ? `${space.attr(`data-space-tablet`)}px` : space.attr(`data-space-tablet`) );
			} else {
				space.css(`height`, this.numberTest.test( space.attr(`data-space-desktop`) ) ? `${space.attr(`data-space-desktop`)}px` : space.attr(`data-space-desktop`) );
			}
		})
	},
}

export default EmptySpace;