import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-qualificationsGrid:not([aicb-component-ready])`,
		parts: {
			slickWrapper: `.aicb-qualificationsGrid-slick_wrapper`,
			slickDots: '.aicb-qualificationsGrid-slick_navigation-dots',
			arrows: '.aicb-qualificationsGrid-slick_navigation-arrows',
		}
	}

	const QualificationsGrid = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const slidesToShow = {
			medium: 2,
			large: 3,
			xlarge: 4
		}
		const slickWrapper = element.querySelector( selectors.parts.slickWrapper );
		const slickDots = element.querySelector( selectors.parts.slickDots );
		const arrows = element.querySelector( selectors.parts.arrows );
		const maxSlides = Array.from( slickWrapper.querySelectorAll(':scope > *') ).length;
		

		const slickOptions = {
			slidesToShow: 1.5,
			swipeToSlide: true,
			arrows: true,
			dots: true,
			autoplay: false,
			infinite: false,
			centerMode: true,
			mobileFirst: true,
			centerPadding: 0,
			responsive: [
				{
					breakpoint: mediaQuery.breakpoint.medium,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.medium ),
						swipeToSlide: false,
						slidesToScroll: Math.min( maxSlides, slidesToShow.medium ),
						centerMode: maxSlides < slidesToShow.medium ? true : false,
						arrows: true,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.large,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.large ),
						swipeToSlide: false,
						slidesToScroll: Math.min( maxSlides, slidesToShow.large ),
						centerMode: maxSlides < slidesToShow.medium ? true : false,
						arrows: true,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.xlarge,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.xlarge ),
						swipeToSlide: false,
						slidesToScroll: Math.min( maxSlides, slidesToShow.xlarge ),
						centerMode: maxSlides < slidesToShow.xlarge ? true : false,
						arrows: true,
					}
				},
			]
		}

		const initSlick = () => {
			$( slickWrapper ).slick( {
				...slickOptions,
				...{ appendArrows: arrows, appendDots: slickDots },
			})
		}

		const initComponent = () => {
			initSlick();
		}


		// Function calls here ====>
		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new QualificationsGrid( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new QualificationsGrid({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()