import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-library:not([aicb-component-ready])`,
    parts: {
			slickWrapper: '.aicb-library-books-slick',
			slickArrows: '.aicb-library-books-slick_navigation-arrows',
    }
	}
	const Library = ( element ) => {
    element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;

		const slickWrapper = element.querySelector( selectors.parts.slickWrapper )
		const slickArrows = element.querySelector( selectors.parts.slickArrows );

    const slickOptions = {
			arrows: true,
			dots: false,
			autoplay: false,
			infinite: false,
			variableWidth: false,
			appendArrows: slickArrows,
			responsive: [
				{
					breakpoint: mediaQuery.breakpoint.medium,
					settings: {
						slidesToShow: 1,
						centerMode: true,
						variableWidth: true
					}
				}
			]
		}

		const beforeChange = ( e, slick ) => {
			Array.from( slick.$slides ).forEach( slide => {
				const tooltip = slide.querySelector('[data-tooltip]');
				if ( typeof tooltip !== 'undefined' && tooltip !== null ) {
					$(tooltip).foundation('hide');
				}
			})
		}

		const afterChange = ( e, slick, currentSlide ) => {
			const tooltip = slick.$slides[ currentSlide ].querySelector('[data-tooltip]');
			if ( typeof tooltip !== 'undefined' && tooltip !== null ) {
				$(tooltip).foundation('show');
			}
		}

    const initSlick = () => {
			const slidesToShow = typeof slickWrapper.getAttribute('data-aicb-library-columns') !== undefined && slickWrapper.getAttribute('data-aicb-library-columns') !== 'false' ? parseInt( slickWrapper.getAttribute('data-aicb-library-columns') ) : 4;
			if ( slickWrapper !== null && ! slickWrapper.classList.contains( 'slick-initialized' ) ) {
				$( slickWrapper ).slick( {
					...slickOptions,
					...{
						slidesToShow: slidesToShow,
					}
				})

				$( slickWrapper ).on('beforeChange', ( e, slick ) => {
					beforeChange( e, slick );
				})
				$( slickWrapper ).on('afterChange', ( e, slick, currentSlide ) => {
					afterChange( e, slick, currentSlide );
				})
			}

      window.addEventListener('resizeEnd', e => {
        if ( slickWrapper.classList.contains( 'slick-initialized' ) ) { 
          $( slickWrapper ).slick('setPosition')
        }
			})
		}

		const initComponent = () => {
			initSlick();
		}

		// Function calls here ====>
		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new Library( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new Library({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()