import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-peoplesRowsCarousel:not([aicb-component-ready])`,
		parts: {
			slickWrapper: `.aicb-peoplesRowsCarousel-slick_wrapper`,
			slickDots: '.aicb-peoplesRowsCarousel-slick_navigation-dots',
			arrows: '.aicb-peoplesRowsCarousel-slick_navigation-arrows',
            pagination: '.aicb-peoplesRowsCarousel-slick_navigation-pagination',
			paginationCurrent: '.aicb-peoplesRowsCarousel-slick_navigation-pagination .pagination-current',
			paginationMax: '.aicb-peoplesRowsCarousel-slick_navigation-pagination .pagination-max',
		}
	}

	const PeoplesRowsCarousel2 = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const slidesToShow = {
			medium: 2,
			large: 3,
			xlarge: 3
		}
		const slickWrapper = element.querySelector( selectors.parts.slickWrapper );
		const slickDots = element.querySelector( selectors.parts.slickDots );
		const arrows = element.querySelector( selectors.parts.arrows );
        const paginationCurrent = element.querySelector( selectors.parts.paginationCurrent );
		const paginationMax = element.querySelector( selectors.parts.paginationMax );
		const maxSlides = Array.from( slickWrapper.querySelectorAll(':scope > *') ).length;
		

		const slickOptions = {
			// slidesToShow: 1.5,
			slidesToShow: 1,
			swipeToSlide: true,
			arrows: true,
			dots: true,
			autoplay: false,
			infinite: true,
			centerMode: false,
			mobileFirst: true,
			centerPadding: 0,
            slidesToScroll: 1,
            // slidesToScroll: Math.min( maxSlides, 1 ),
			responsive: [
				{
					breakpoint: mediaQuery.breakpoint.medium,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.medium ),
						swipeToSlide: false,
						// slidesToScroll: Math.min( maxSlides, slidesToShow.medium ),
						// centerMode: maxSlides < slidesToShow.medium ? true : false,
						centerMode: false,
						arrows: true,
					}
				},
				{
                    breakpoint: mediaQuery.breakpoint.large,
					settings: {
                        slidesToShow: Math.min( maxSlides, slidesToShow.large ),
						swipeToSlide: false,
						// slidesToScroll: Math.min( maxSlides, slidesToShow.large ),
						// centerMode: maxSlides < slidesToShow.medium ? true : false,
                        centerMode: true,
						arrows: true,
					}
				},
				{
                    breakpoint: mediaQuery.breakpoint.xlarge,
					settings: {
                        slidesToShow: Math.min( maxSlides, slidesToShow.xlarge ),
						swipeToSlide: false,
						// slidesToScroll: Math.min( maxSlides, slidesToShow.xlarge ),
						// centerMode: maxSlides < slidesToShow.xlarge ? true : false,
                        centerMode: true,
						arrows: true,
					}
				},
			]
		}

        const paginate = ( slick ) => {
			// console.log(`paginate`, slick )
			if ( typeof slick.$dots !== 'undefined' && slick.$dots !== null && slick.$dots.length > 0 ) {
				const slickDots = slick.$dots[0];
				const maxCount = Array.from( slickDots.querySelectorAll(':scope > li') ).length;
				const currentDot = Array.from( slickDots.querySelectorAll(':scope > li')).indexOf( slickDots.querySelector(':scope > .slick-active') ) || 0;
				paginationMax.innerHTML = maxCount;
				paginationCurrent.innerHTML = currentDot + 1;
			} else {
				paginationMax.innerHTML = 1;
				paginationCurrent.innerHTML = 1;
			}
		}

		const initSlick = () => {
			$( slickWrapper ).slick( {
				...slickOptions,
				...{ appendArrows: arrows, appendDots: slickDots },
			})
		}

		const initComponent = () => {
            $( slickWrapper ).on('init', ( e, slick ) => {
				paginate( slick )
			})
			$( slickWrapper ).on('breakpoint', ( e, slick ) => {
				paginate( slick )
			})
			$( slickWrapper ).on('afterChange', ( e, slick, currentSlide, nextSlide ) => {
				paginate( slick )
			})

			initSlick();
		}


		// Function calls here ====>
		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new PeoplesRowsCarousel2( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new PeoplesRowsCarousel2({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()