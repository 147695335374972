// Import utilities
import mediaQuery from '../util/aicb.mediaquery'
import isSafari from '../util/aicb.safari'
// Import components
require( './_aicb.giantHeroBanner' )
require( './_aicb.committeeTab' )
require('./_aicb.eventSpeakers')
require('./_aicb.reveal')
require('./_aicb.qualificationsGrid')
require('./_aicb.revealPopup')
require('./_aicb.backToTop')
require('./_aicb.eventsAndAnnouncements')
require('./_aicb.membershipCards')
require('./_aicb.peopleRowsCarousel2')
require('./_aicb.eventListSpeaker')
require('./_aicb.responsiveTable')
require('./_aicb.eventsList')
require('./_aicb.library')
require('./_aicb.membershipFeatureBlock');
require('./_aicb.videoAndBankingInsights')
import BoxBanner from './_aicb.boxedBanner'
import EmptySpace from './_aicb.emptySpace'
import Announcements from './_aicb.announcements'
import Articles from './_aicb.announcements'
import ExpandBoxRight from './_aicb.expandBoxRight'
import InfoBoxCarousel from './_aicb.infoBoxCarousel'
import PeopleModalCarousel from './_aicb.peoplesModalCarousel'
// import PeopleRowsCarousel from './_aicb.peopleRowsCarousel'
import SemiCarouselBox from './_aicb.semiCarouselBox'
// import Library from './_aicb.library'
import MapMarkerMalaysia from './_aicb.mapMarkerMalaysia'
import ImageGallery from './_aicb.imageGallery'
import RevealPopup from './_aicb.revealPopup'
import ForceNewTab from './_aicb.forceNewTab'
import Pagination from './_aicb.paginate'
import ResponsiveTable from './_aicb.responsiveTable'

var aicb = {

	init: function( ) {
		isSafari.init();

		// Initiate Boxed Banner
		if ( $('.aicb-boxedBanner').length > 0 ) {
			BoxBanner.init();
		}

		// Initiate Empty Space
		if ( $('.aicb-emptySpace').length > 0 ) {
			EmptySpace.init();
		}

		// Initiate Announcements
		if ( $('.aicb-homeAnnouncements').length > 0 ) {
			Announcements.init();
		}

		// Initiate Announcements
		if ( $('.aicb-homeArticles').length > 0 ) {
			Articles.init();
		}

		// Initiate Expand Box Right
		if ( $('.aicb-expandBoxRight').length > 0 ) {
			ExpandBoxRight.init();
		}

		// Initiate Info Box Carousel
		if ( $('.aicb-infoBoxCarousel').length > 0 ) {
			InfoBoxCarousel.init();
		}

		// Initiate People Model Carousel
		if ( $('.aicb-peoplesModalCarousel').length > 0 ) {
			// PeopleModalCarousel.init();
		}

		// // Initiate People Rows Carousel
		// if ( $('.aicb-peoplesRowsCarousel').length > 0 ) {
		// 	PeopleRowsCarousel.init();
		// }

		// Initiate Semi Carousel Box
		if ( $('.aicb-semiCarouselBox').length > 0 ) {
			SemiCarouselBox.init();
		}

		// Initiate Library
		/* if ( $('.aicb-library').length > 0 ) {
			Library.init();
		} */

		// Initiate Map Marker Malaysia
		if ( $('.aicb-mapMarkerMalaysia').length > 0 ) {
			MapMarkerMalaysia.init();
		}

		// Initiate the imageGallery
		if ( $('.aicb-imageGallery').length > 0 ) {
			ImageGallery.init();
		}
		
		if( $('.paginate-any').length > 0 ) {
			Pagination.init();
		}

		ForceNewTab.init();
	},

	resize: function() {
		mediaQuery.resize();

		// Resize Boxed Banner
		if ( $('.aicb-boxedBanner').length > 0 ) {
			BoxBanner.resize();
		}

		// Resize Empty Space
		if ( $('.aicb-emptySpace').length > 0 ) {
			EmptySpace.resize();
		}

		// Resize Announcements
		if ( $('.aicb-homeAnnouncements').length > 0 ) {
			Announcements.resize();
		}

		// Resize Announcements
		if ( $('.aicb-homeArticles').length > 0 ) {
			Articles.resize();
		}

		// Resize Expand Box Right
		if ( $('.aicb-expandBoxRight').length > 0 ) {
			ExpandBoxRight.resize();
		}

		// Resize Info Box Carousel
		if ( $('.aicb-expandBoxRight').length > 0 ) {
			InfoBoxCarousel.resize();
		}

		// Resize Info Box Carousel
		if ( $('.aicb-peoplesModalCarousel').length > 0 ) {
			// PeopleModalCarousel.resize();
		}

		// // Resize Info Box Carousel
		// if ( $('.aicb-peoplesRowsCarousel').length > 0 ) {
		// 	PeopleRowsCarousel.resize();
		// }

		// Resize Semi Carousel Box
		if ( $('.aicb-semiCarouselBox').length > 0 ) {
			SemiCarouselBox.resize();
		}

		// Resize Library
		/* if ( $('.aicb-library').length > 0 ) {
			Library.resize();
		} */

		// Resize mapMarker
		if ( $('.aicb-mapMarkerMalaysia').length > 0 ) {
			MapMarkerMalaysia.resize();
		}

		// Resize imageGallery
		if ( $('.aicb-imageGallery').length > 0 ) {
			ImageGallery.resize();
		}
	}

}

export default aicb;