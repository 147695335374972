import $ from 'jquery';
import jQuery from 'jquery';
window.$ = $;
window.jQuery = $;

import 'lazysizes';
import 'lazysizes/plugins/object-fit/ls.object-fit';
import Foundation from 'foundation-sites/js/foundation';
require('../vendor/jquery.matchHeight');

// Utilities
require('./util/windowresize');

import AccordionMenu from './_accordion-menu';
import HeaderScroll from './_mobile-header-scrolled';
import SearchPopup from './_search-popup';
import aicb from './aicb_components/aicb';
import { debounce } from './util/debounce';

require('./_onPageLoadScroll');
require('./_pageWithSidebarContent')
require('./_formContainer')

import validate from 'jquery-validation';
/**
 * Global Ready event
 */
$(function () {
	// Initiate Foundation
	$(document).foundation();
	// Manually reinitiate foundation mediaQuery
	Foundation.MediaQuery._init();
	require('./_mobile-header-scrolled');


	window.addEventListener('load', () => {
		$(document).foundation();
	})
	/**
	 * Initiate all AICB scripts here
	 */
	// Headers
	if ( ( $('#popup-search-wrapper') ) && ( $( '.popup-search-icon-button' ).length > 0 ) ) {
		// SearchPopup.init();
	}
	// Footer
	AccordionMenu.init();
	jQuery.validator.addMethod("emailStrict", function(value, element) {

		var re = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?)\.){3}(?:25[0-5]|2[0-4][0-9]|[01]?[0-9][0-9]?|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/

		return this.optional(element) || re.test(String(value).toLowerCase());
	}, "Please enter a valid email address.");

	jQuery.validator.addMethod("internationalNumber", function(value, element) {
		var re = /([0-9\s\-]{7,})(?:\s*(?:#|x\.?|ext\.?|extension)\s*(\d+))?$/

		return this.optional(element) || re.test(String(value).toLowerCase());
	}, "Please enter a valid phone number.");


	$('#footer-contact').validate(
		{
			rules: {
				// simple rule, converted to {required:true}
				name: {
					required: true,
				},
				// compound rule
				email: {
					required:  {
						depends:function(){
							$(this).val($.trim($(this).val()));
							return true;
						}
					},
					emailStrict: true
				},
				phone: {
					required:  {
						depends:function(){
							$(this).val($.trim($(this).val()));
							return true;
						}
					},
					internationalNumber: true
				},
				message: {
					required: true,
				}
			}
		}
	);
	$('#footer-contact').on('submit', function(e) {
		if ( $('#footer-contact').data('validator').errorList.length > 0 ) {
			e.preventDefault();
			alert("Please complete the form.");
			
		} else if( grecaptcha.getResponse() == "") {
			e.preventDefault();
			alert("Please complete the recaptcha.");
		} else {
			var data = $(this).serialize();
			$.post('/wheelform/message/send',data,
				function(response) {
					if (response.success) {
						alert("Form submitted!");
						$(e.target)[0].reset()
						//reponse.message is the message saved in the Form Settings
					} else {
						// response.values will contain user submitted values
						// response.errors will be an array containing any validation errors that occurred, indexed by field name
						// e.g. response.error['email'] => ['Email is required', 'Email is invalid']
						alert('An error occurred. Please try again.');
					}
				}
			); 
		}
		return;
	});

	function updateCopyrightYear() {
		const currentYear = new Date().getFullYear();
		const footerElem = $('.footer-copyright p');
		const footerText = footerElem.html();
		const yearMatch = footerText.match(/\d{4}/);

		if (yearMatch && parseInt(yearMatch[0]) !== currentYear) {
			const updatedText = footerText.replace(yearMatch[0], currentYear);
			footerElem.html(updatedText);
		}
	}

	updateCopyrightYear();

	// Modernizr
	// ...
	
	// Components
	aicb.init();

	// Spaghetti
	require('./_spaghetti-table-to-not-table')
});

/**
 * Global Resize Events
 * This is included: Load, resize, orientationchange
 * Only run after resize finished
 */
$(window).bind('resizeEnd', function () {
	/**
	 * Resize all AICB scripts here
	 */
	// Header
	// Footer
	AccordionMenu.resize();
	// Components
	aicb.resize();

	// Resize slick
	$('.slick-initialized').slick('resize');
});

$(window).scroll( function(e) {
	/**
	 * Scroll all AICB scripts here
	 */
	// Header
	if ( $(`#site-header`).length > 0) {
		HeaderScroll.scroll(e);
	}

});