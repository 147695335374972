import $ from 'jquery';

import mediaQuery from '../util/aicb.mediaquery';
import 'slick-carousel';

const InfoBoxCarousel = {
	main: '.aicb-infoBoxCarousel',
	classes: {
		slick: '.aicb-infoBoxCarousel-slick_wrapper',
		slickdots: '.aicb-infoBoxCarousel-slick_navigation-dots',
	},

	slickOptions: {
		slidesToShow: 3,
		centerMode: true,
		arrows: false,
		dots: true,
		autoplay: false,
		responsive: [
			{
				breakpoint: mediaQuery.breakpoint.large,
				settings: {
					slidesToShow: 2,
				}
			},
			{
				breakpoint: mediaQuery.breakpoint.medium,
				settings: {
					slidesToShow: 1,
				}
			},
		]
	},

	init: function() {
		this._initslick();
	},
	
	resize: function() {
	},

	_initslick: function() {
		$(`${this.main}`).each( (i, infoBox) => {
			let block = $(infoBox);
			let wrapper = block.find(`${this.classes.slick}`);
			let slickDots = block.find(`${this.classes.slickdots}`);
			if ( ! wrapper.hasClass( 'slick-initialized' )) {
				wrapper.slick({
					...this.slickOptions,
					...{ 'appendDots': slickDots }
				})
			}
		} );
	},
}

export default InfoBoxCarousel;