import $ from 'jquery';

import mediaQuery from '../util/aicb.mediaquery';
import 'slick-carousel';

const BoxBanner = {
	main: '.aicb-boxedBanner',
	classes: {
		picture: {
			slick: '.aicb-boxedBanner-picture-slick_wrapper',
			picture: '.aicb-boxedBanner-picture',
		},
		box: {
			slick: '.aicb-boxedBanner-box-slick_wrapper',
			content: '.aicb-boxedBanner-box-content',
			arrows: '.aicb-boxedBanner-box-slick_navigation',
		},
		title: {
			slick: '.aicb-boxedBanner-box-next-slide-title-slick-wrapper',
			content: '.aicb-boxedBanner-box-next-slide-title',
		},
	},
	picSlickOptions: {
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: false,
		arrows: false,
	},

	boxSlickOptions: {
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		arrows: true,
	},

	titleSlickOptions: {
		slidesToShow: 1,
		slidesToScroll: 1,
		adaptiveHeight: true,
		arrows: false,
		draggable: false,
		fade: true,
	},

	init: function() {
		this._initslick();
	},
	
	resize: function() {

	},

	_initslick: function() {
		$(`${this.main}`).each( (i,boxBanner) => {
			let banner = $(boxBanner);
			let picture = banner.find(`${this.classes.picture.picture}`);
			let box = banner.find(`${this.classes.box.content}`);
			let title = banner.find(`${this.classes.title.content}`);

			if ( picture.length > 1 || box.length > 1) {
				let pictureSlick = banner.find( `${this.classes.picture.slick}` );
				let boxSlick = banner.find( `${this.classes.box.slick}` );
				let boxArrows = banner.find( `${this.classes.box.arrows}`);
				let titleSlick = banner.find( `${this.classes.title.slick}` );

				// Add class for `asNavFor` slick option
				pictureSlick.addClass( `aicb-slick-pic-${i}` );
				boxSlick.addClass( `aicb-slick-box-${i}` );
				titleSlick.addClass( `aicb-slick-title-${i}` );

				// Initiate slick
				pictureSlick.slick({
					...this.picSlickOptions, ...{
					// 'asNavFor': `.aicb-slick-box-${i}, .aicb-slick-title-${(i == title.length - 1 ? 0 : i + 1)}`
					'asNavFor': `.aicb-slick-box-${i}`
				}}).on('afterChange', function(event, slick, currentSlide){
					// Update slider 3 after slider 1 changes
					titleSlick.slick('slickGoTo', (currentSlide + 1) % title.length);
				});
				boxSlick.slick({
					...this.boxSlickOptions, ...{
					// 'asNavFor': `.aicb-slick-pic-${i}, .aicb-slick-title-${(i == title.length - 1 ? 0 : i + 1)}`,
					'asNavFor': `.aicb-slick-pic-${i}`,
					'appendArrows': boxArrows 
				}}).on('afterChange', function(event, slick, currentSlide){
					// Update slider 3 after slider 2 changes
					titleSlick.slick('slickGoTo', (currentSlide + 1) % title.length);
				});
				titleSlick.slick({
					...this.titleSlickOptions, ...{
					// 'asNavFor': `.aicb-slick-box-${i}, .aicb-slick-pic-${i}`
					initialSlide: title.length ? 1 : 0
				} });
			}
		})
	}
}

export default BoxBanner;