import $ from 'jquery';
import 'slick-carousel';
import mediaQuery from '../util/aicb.mediaquery';
import { debounce } from '../util/debounce';

( function(){
	const selectors = {
		self: `.aicb-giantHeroBanner:not([aicb-component-ready])`,
		parts: {
			slickwrapper: '.aicb-giantHeroBanner-slick_wrapper',
			youtubeWrapper: '.aicb-youtube-video-background-wrapper',
			slickdots: '.aicb-slick_dots',
			slickarrows: '.aicb-slick_arrows',
			slides: '.aicb-giantHeroBanner-slide',
			box: '.aicb-giantHeroBanner-box',
			social: '.aicb-heroBanner-box-social',
		},
		nextSiblingOffset: `.aicb-ctaRow`,
	}

	// Component code:
	const GiantHeroBanner = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const isFullHeight = element.querySelector('.aicb-giantHeroBanner-slick_wrapper.fullheight') !== null ? true : false;
		const minImageHeight = 500;
		const minImageHeightMobile = 500;
		const slickOptions = {
			slidesToShow: 1,
			slidesToScroll: 1,
			adaptiveHeight: false,
			arrows: true,
			dots: true,
			autoplay: false,
		}
		let autoPlayTimer;
		let dimension = {
			width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
			height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
		}


		const logoAlignment = () => {
			const siteHeader = document.querySelector('#site-header');
			const headerHeight = siteHeader !== null ? siteHeader.clientHeight : 0;
			const logoDimension = siteHeader.querySelector('.logo') !== null ? siteHeader.querySelector('.logo').getBoundingClientRect() : { top: 0, left: 0, width: 0, height: 0 } ;
			const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			const scrollPosition = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop || 0;
			const logoEdge = {
				x: Math.max( 0, logoDimension.left + logoDimension.width ),
				y: Math.max( 0, logoDimension.top + logoDimension.height + scrollPosition - headerHeight )
			}
			element.style.setProperty('--var-box-top', `${ logoEdge.y }px`)
			element.style.setProperty('--var-box-left', `${ logoEdge.x }px`)
		}


		const autoPlayTimerGet = ( slick, n ) => {
			let timerDuration = 6000;
			const currentSlideElement = slick.$slides[ n ];
			if ( typeof currentSlideElement !== undefined && currentSlideElement !== null ) {
				const slide = currentSlideElement.querySelector('.aicb-giantHeroBanner-slide');
				timerDuration = slide !== null && ! isNaN( parseInt( slide.getAttribute('data-duration') ) ) ? parseInt( slide.getAttribute('data-duration') ) : 6000;
			}
			return timerDuration;
		} 

		const pauseVideo = ( video ) => {
			let playPromise = video.play();
			if (playPromise !== undefined) {
				playPromise.then( e => {
					video.pause();
				})
				.catch( error => {
					console.error( error )
				});
			}
		}

		const loadVideo = () => {
			// console.log('load video')
			return new Promise ( (resolve,reject) => {
				const videoWrappers = Array.from( element.querySelectorAll('video') );
				videoWrappers.forEach( videoWrapper => {
					const videosrcsets = Array.from( videoWrapper.querySelectorAll('source') );
					videoWrapper.src = null;
					videoWrapper.removeAttribute('src');
					let videoSource = null;

					// Get video src
					if ( dimension.width <= mediaQuery.breakpoint.medium || dimension.width <= dimension.height ) {
						videoSource = videosrcsets.find( srcset => srcset.getAttribute('data-media') === 'mobile' );
						if ( typeof videoSource === 'undefined' || videoSource === null ) {
							videoSource = videosrcsets.find( srcset => srcset.getAttribute('data-media') === 'desktop' );
						}
					} else {
						videoSource = videosrcsets.find( srcset => srcset.getAttribute('data-media') === 'desktop' );
					}
					// Set video src
					if ( typeof videoSource !== 'undefined' ) {
						videoWrapper.setAttribute( 'src', videoSource.getAttribute('data-srcset') )
						if ( videoWrapper.closest('.slick-slide.slick-active') !== null ) {
							videoWrapper.play()
						}
					}
				})

				resolve();
			})
		}


		/* const reloadVideo = () => {
			return new Promise ( ( resolve, reject ) => {
				
			})
		} */

		const playPauseVideo = ( slickWrapper ) => {
			$( slickWrapper ).on( 'afterChange', ( e, slick, currentSlide ) => {
				// console.log( `playPauseVideo`, slick, currentSlide  )
				Array.from( slick.$slides ).forEach( slide => {
					const video = slide.querySelector('video');
					if ( ! slide.classList.contains('slick-current') ) {
						if ( video !== null ) {
							pauseVideo( video )
						}
					} else {
						if ( video !== null ) {
							video.currentTime = 0;
							video.play();
						}
					}
				})
			})
			if ( slickWrapper.classList.contains('slick-initialized') ) {
			}
		}


		const customAutoplayLoop = ( slickWrapper ) => {
			if ( slickWrapper.classList.contains('slick-initialized') ) {
				$( slickWrapper ).on( 'afterChange', ( e, slick, currentSlide ) => {
					let duration = autoPlayTimerGet( slick, currentSlide );		
					clearTimeout( autoPlayTimer );
					if ( duration > 0 ) {
						autoPlayTimer = setTimeout( () => { $( slickWrapper ).slick('slickNext') }, duration )
					}
				})
			}
		}

		const setHeight = () => {
			return new Promise( ( resolve, reject ) => {
				let offsetElement = null;
				if ( typeof element.nextElementSibling !== 'undefined' && element.nextElementSibling !== null && element.nextElementSibling.matches( selectors.nextSiblingOffset ) ) {
					offsetElement = element.nextElementSibling;
				}			
				const offsetElementHeight = offsetElement !== null ? offsetElement.clientHeight : 0;
				const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
				const viewportHeight = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
				const headerHeight = document.querySelector('#site-header') !== null ? document.querySelector('#site-header').clientHeight : 0;
				
				if ( isFullHeight ) {
					// Breakpoint Up
					if ( viewportWidth >= mediaQuery.breakpoint.medium ) {
						const imageHeight = Math.max( minImageHeight, viewportHeight - offsetElementHeight - headerHeight );
						const maxHeight = viewportWidth * 1080 / 1920;
						dimension.height = imageHeight;
						element.style.setProperty('--var-image-height', `${ Math.min( imageHeight, maxHeight ) }px`);
						element.style.removeProperty('--var-social-height')
					}
					// Breakpoint Down
					else {
						const imageHeight = Math.max( minImageHeightMobile, viewportHeight - offsetElementHeight );
						// const imageHeight = Math.max( viewportHeight, viewportHeight )
						dimension.height = imageHeight;
						const socialBox = element.querySelector( selectors.parts.social );
						const socialHeight = socialBox !== null ? socialBox.clientHeight : 0;
						element.style.setProperty('--var-image-height', `${ imageHeight }px`);
						element.style.setProperty('--var-social-height', `${ socialHeight }px` );
					}
				}
				resolve();
			})
		}

		const initSlick = ( slickWrapper ) => {
			const appendDots = slickWrapper.parentElement.querySelector( selectors.parts.slickdots );
			const appendArrows = slickWrapper.parentElement.querySelector( selectors.parts.slickarrows );
			
			if ( ! slickWrapper.classList.contains('slick-initialized') ) {
				$( slickWrapper ).on( 'beforeChange', ( e, slick, currentSlide ) => {
					clearTimeout( autoPlayTimer );
				})
				$( slickWrapper ).on( 'init', ( e, slick ) => {
					let duration = autoPlayTimerGet( slick, 0 );		
					if ( duration > 0 ) {
						autoPlayTimer = setTimeout( () => { $( slickWrapper ).slick('slickNext') }, duration )
					}
				} )

				$( slickWrapper ).slick({ ...slickOptions, appendDots, appendArrows })
			}
		}

		const reintSlick = ( slickWrapper ) => {
			$( slickWrapper ).slick('setPosition');
		}

		const debounceResize = debounce( ( slickWrapper ) => {
			dimension.width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			dimension.height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			setHeight();
			window.requestAnimationFrame( () => {
				reintSlick( slickWrapper );
				logoAlignment();
				loadVideo();
			})
		}, 500 )

		const initComponent = async () => {
			const slickWrapper = element.querySelector( selectors.parts.slickwrapper );
			setHeight().then( () => {
				initSlick( slickWrapper );
				customAutoplayLoop( slickWrapper );
				logoAlignment();
			});
			window.addEventListener('resizeEnd', e => {
				debounceResize( slickWrapper );
			} )
			loadVideo();
			playPauseVideo( slickWrapper );
			Array.from( document.querySelectorAll('#site-header, #site-header .logo') ).forEach( item => {
				item.addEventListener('resize', e => {
					debounceResize( slickWrapper );
				})
			})

		}

		// Function calls here ====>
		initComponent();
	}



	// Wait for loading to initiate component
	function onDocumentReady(){
    let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new GiantHeroBanner( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new GiantHeroBanner({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }

})()