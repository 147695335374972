import mediaQuery from './util/aicb.mediaquery';

(function () {

  const qualificationMenuFirstChildActive = ( qualificationMenu ) => {
    let firstChildNotActive = false;
    if ( qualificationMenu !== null ) {
      const activeNonFirstTab = qualificationMenu.querySelector('.aicb-tabs-tab.is-active:not(:first-child)');
      firstChildNotActive = activeNonFirstTab !== null;
    }
    return firstChildNotActive;
  }
  
  const scrollTowardAnchor = ( anchorItem ) => {
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const scrollBottom = anchorItem.getBoundingClientRect().top + anchorItem.getBoundingClientRect().height + document.documentElement.scrollTop;
    window.scrollTo({top: viewportWidth >= mediaQuery.breakpoint.medium ? scrollBottom : scrollBottom - 175, behavior: "smooth"});
  }

  const scrollPassHeroBanner = () => {
    const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
    const heroBanner = document.querySelector('.aicb-heroBanner');
    if ( heroBanner !== null ) {
      const scrollBottom = heroBanner.getBoundingClientRect().top + heroBanner.getBoundingClientRect().height + document.documentElement.scrollTop;
      window.scrollTo({top: viewportWidth >= mediaQuery.breakpoint.medium ? scrollBottom : scrollBottom - 100, behavior: "smooth"});
    }
  }

  function onPageLoadScroll () {
    // console.log(`onPageLoadScroll`)
    const location = window.location;
    const hasAnchor = location.hash.length > 0 && /\D/.test( location.hash[1] ) ? true : false;

    // Scroll to anchor if have anchor
    const anchorItem = hasAnchor ? document.querySelector( `${ location.hash }` ) : null;
    // Scroll after heroBanner if body has this class
    const entryWithAutoScroll = document.querySelector( '.entryWithAutoScroll' );
    // Scroll after heroBanner if it was non-first menu item was active in Qualification Menu
    const qualificationMenu = document.querySelector( '.aicb-qualification-menu-wrapper' );

    if ( anchorItem !== null && anchorItem.getAttribute('role') !== 'tabpanel' ) {
      // console.log('onPageLoadScroll for anchor', anchorItem )
      setTimeout( () => {
        scrollTowardAnchor( anchorItem )
      }, 500 )
    } else if ( entryWithAutoScroll !== null ) {
      setTimeout( () => {
        scrollPassHeroBanner();
      }, 500 )
    } else if ( qualificationMenu !== null ) {
      if ( qualificationMenuFirstChildActive( qualificationMenu ) ) {
        setTimeout( () => {
          scrollPassHeroBanner();
        }, 500 )
      }
    }
  }
  if (document.readyState === 'complete') {
    onPageLoadScroll();
  } else {
    window.addEventListener('load', () => {
      onPageLoadScroll();
    });
  }

})()