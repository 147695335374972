import $ from 'jquery';
import { createPopper, detectOverflow } from '@popperjs/core';

import mediaQuery from '../util/aicb.mediaquery';

( function(){
	const selectors = {
		self: `.aicb-revealPopup:not([aicb-component-ready])`,
	}

	// Component code:
	function RevealPopup ( element ) {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const id = element.id;
		let popperInstance = null;
		let overflow = null;

		function debounce( fn, delay = 500 ) {
			let timer;
			return (() => {
				clearTimeout(timer);
				timer = setTimeout(() => fn(), delay);
			})();
		}

		const onMouseOver = ( triggerer, e ) => {
			element.classList.add('revealed', 'hovering')
			window.requestAnimationFrame( async () => {
			const state = await popperInstance.update();
			})
		}
		const onMouseLeave = ( triggerer, e ) => {
			element.classList.remove('hovering')
			setTimeout(() => {
				if( ! $( triggerer ).is(':hover') ) {
					element.classList.remove('revealed')
				}
			}, 50);

		}

		const waitForParent = ( triggerer, callback ) => {
			const parentElement = triggerer.closest('div');
			if ( parentElement !== null ) {
				const rect = parentElement.getBoundingClientRect();
				if ( rect.width <= 0 ) {
					const resizeObserver = new ResizeObserver( function( entries ) {
						const resObs = this;
						for ( const entry of entries ) {
							if ( typeof entry.contentRect !== 'undefined' ) {
								const contentBoxSize = entry.contentRect.width;
								if ( contentBoxSize > 0 && ! ( triggerer.getAttribute('href') === null || triggerer.getAttribute('href') === '') ) {
									debounce( () => {
										callback( triggerer );
										resObs.disconnect();
									}, 500  )
								}
							}
						}
					}).observe( parentElement )
					/* const resizeObserver = new ResizeObserver ( throttle( (entries) => {
						for ( const entry of entries ) {
							if ( entry.contentRect ) {
								const contentBoxSize = entry.contentRect.width;
								if ( contentBoxSize > 0 && ! ( triggerer.getAttribute('href') === null || triggerer.getAttribute('href') === '') ) {
									callback( triggerer, resizeObserver );
								}
							}
						}
					}, 500 ) ).observe( parentElement ) */
				} else {
					callback( triggerer );
				}
			}
		}

		const initComponent = () => {
			const triggerers = Array.from( document.querySelectorAll(`[href="#${ id }"]`) );
			triggerers.forEach( triggerer => {
				waitForParent( triggerer, ( triggerer ) => {
					triggerer.setAttribute( 'data-tooltip-id', id )
					triggerer.removeAttribute( 'href' );
					popperInstance = createPopper( triggerer, element, {
						placement: 'bottom-end',
						modifiers: [
							{
								name: 'preventOverflow',
								options: {
									altAxis: true,
									boundary: document.querySelector('main'),
								}
							},
							{
								name: 'flip',
								options: {
									fallbackPlacements: ['top', 'bottom', 'left', 'right'],
								},
							},
							/* {
								name: 'offset',
								options: {
									offset: [ parseInt($(triggerer).innerHeight()), parseInt($(triggerer).innerWidth()) * -0.5 ]
								}
							}, */
							{
								name: 'computeStyles',
								options: {
									adaptive: false,
								},
							},
						]
					} )
					triggerer.addEventListener('mouseover', e => { onMouseOver( triggerer, e ) } )
					triggerer.addEventListener('mouseout', e => { onMouseLeave( triggerer, e ) } )
				})
			})
		}

		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
    let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new RevealPopup( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new RevealPopup({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})();