import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import { debounce } from '../util/debounce';

(function () {
	const selectors = {
		self: `.aicb-homeVideoAndBankingInsight:not([aicb-component-ready])`,
		youtubeWrapper: `[data-youtube-id]`,
	}

	function VideoAndBankingInsights(element) {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
    const youtubeWrappers = element.querySelectorAll( selectors.youtubeWrapper )

		const loadYoutube = ( youtubeWrapper ) => {
      youtubeWrapper.addEventListener('click', e => {
        youtubeWrapper.classList.add('played')
        const youtubeId = youtubeWrapper.getAttribute('data-youtube-id');
        youtubeWrapper.innerHTML = `<iframe class="yt-wrap" src="https://www.youtube.com/embed/${ youtubeId }?enablejsapi=1&autoplay=1" frameborder="0" allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" allowfullscreen>`
       }, { once: true })
		}

    const loadThumbnail = ( youtubeWrapper ) => {
      const youtubeId = youtubeWrapper.getAttribute('data-youtube-id');
      youtubeWrapper.style.setProperty('--var-yt-thumbnail', `url('https://i.ytimg.com/vi/${ youtubeId }/maxresdefault.jpg`);
    }
		
		const initComponent = () => {
      Array.from( youtubeWrappers ).forEach( youtubeWrapper => {
        loadThumbnail( youtubeWrapper )
        loadYoutube( youtubeWrapper );
        
      })
		}

		// Function calls here ====>
		initComponent();
	}
	// Wait for loading to initiate component
	function onDocumentReady() {
		let elements = document.querySelectorAll(selectors.self);
		for (let i = 0; i < elements.length; i++) {
			new VideoAndBankingInsights(elements[i]);
		};

		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new VideoAndBankingInsights({ element });
							});
						}
					});
				}
			})
		}).observe(document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		})
	}
	if (document.readyState !== 'loading') {
		onDocumentReady();
	} else {
		document.addEventListener('DOMContentLoaded', () => {
			onDocumentReady();
		});
	}

})()
