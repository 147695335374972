import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';

( function(){
	const selectors = {
		self: `.eventsList-speakers-wrapper:not([aicb-component-ready])`,
    parts: {
      speakerList: `.eventsList-speakers`,
      buttons: `.eventList-speaker-button`,
      buttonPrev: `.eventList-speaker-prev`,
      buttonNext: `.eventList-speaker-next`,
    }
	}
	const EventListSpeakers = ( element ) => {
    element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
    const speakerList = element.querySelector( selectors.parts.speakerList )
    const buttonPrev = element.querySelector( selectors.parts.buttonPrev )
    const buttonNext = element.querySelector( selectors.parts.buttonNext )

    const calcSize = () => {
      if ( speakerList.scrollWidth - speakerList.clientWidth <= 1 ) {
        element.classList.add('noScroll');
      } else {
        element.classList.remove('noScroll');
      }
    }
    
    window.addEventListener('resizeEnd', e => {
      calcSize()
    })

		const initComponent = () => {
      calcSize();
      buttonPrev.addEventListener('click', e => {
        speakerList.scroll({
          left: speakerList.scrollLeft - ( speakerList.clientWidth * 0.75 ),
          top: 0,
          behavior: 'smooth'
        });
      })
      buttonNext.addEventListener('click', e => {
        speakerList.scroll({
          left: speakerList.scrollLeft + ( speakerList.clientWidth * 0.75 ),
          top: 0,
          behavior: 'smooth'
        });
      })
		}

		// Function calls here ====>
		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new EventListSpeakers( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new EventListSpeakers({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()