import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import { debounce } from '../util/debounce';

(function () {
	const selectors = {
		self: `.aicb-reveal:not([aicb-component-ready])`,
		html5Video: `.html5video-content video`,
		youtube: `.youtube-content`,
	}
	function Reveal(element) {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const oldId = `${ element.id }`;
		const newId = /^modal-.*/g.test( element.id ) ? element.id : `modal-${ element.id }`;
		element.id = newId;

		// Change old id to new id
		const triggererWithOldId = Array.from( document.querySelectorAll( `[data-open="${ oldId }"], [href="#${oldId}"]` ) );
		const triggerersWithNewId = Array.from(document.querySelectorAll(`[data-open="${ newId }"], [href="#${ newId }"]`));

		let dimension = {
			width: Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0),
			height: Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0),
		}

		const changeTriggersDataAttributes = () => {
			triggererWithOldId.forEach(triggerer => {
				triggerer.setAttribute('data-open', newId);
				triggerer.removeAttribute('href');
			})
			triggerersWithNewId.forEach( triggerer => {
				triggerer.setAttribute('data-open', newId);
				triggerer.removeAttribute('href');
			})
		}
		const pauseVideo = ( video ) => {
			let playPromise = video.play();
			if (playPromise !== undefined) {
				playPromise.then( e => {
					video.pause();
				})
				.catch( error => {
					console.error( error )
				});
			}
		}

		const loadVideo = () => {
			return new Promise ( (resolve,reject) => {
				const videos = Array.from( element.querySelectorAll( selectors.html5Video ) );
				videos.forEach( video => {
					const videosrcsets = Array.from( video.querySelectorAll('source') );
					video.removeAttribute('src');
					let videoSource = null;

					// Get video src
					if ( dimension.width <= mediaQuery.breakpoint.medium || dimension.width <= dimension.height ) {
						videoSource = videosrcsets.find( srcset => srcset.getAttribute('data-media') === 'mobile' );
						if ( typeof videoSource !== undefined ) {
							videoSource = videosrcsets.find( srcset => srcset.getAttribute('data-media') === 'desktop' );
						}
					} else {
						videoSource = videosrcsets.find( srcset => srcset.getAttribute('data-media') === 'desktop' );
					}
					// Set video src
					if ( typeof videoSource !== undefined ) {
						video.setAttribute( 'src', videoSource.getAttribute('data-srcset') )
					}
				})
				resolve();
			})
		}


		const loadYoutube = () => {
			const youtubeContainer = element.querySelector(`[data-youtube-id]`);
			if ( youtubeContainer !== null ) {
				$( element ).on('open.zf.reveal', e => {
					const youtubeId = youtubeContainer.getAttribute('data-youtube-id');
					youtubeContainer.innerHTML = `<iframe class="yt-wrap" src="https://www.youtube.com/embed/${ youtubeId }?enablejsapi=1&autoplay=1" frameborder="0" allow="accelerometer;autoplay;clipboard-write;encrypted-media;gyroscope;picture-in-picture" allowfullscreen>`
				})
				$( element ).on('closed.zf.reveal', e => {
					youtubeContainer.innerHTML = '';
				})
			}
		}
		
		const playPauseVideoEvent = () => {
			$( element ).on('open.zf.reveal', e => {
				const video = element.querySelector('video');
				if ( video !== null ) {
					video.play();
				}
			})
			$( element ).on('closed.zf.reveal', e => {
				const video = element.querySelector('video');
				if ( video !== null ) {
					pauseVideo( video )
				}
			})
		}

		const debounceResize = debounce( () => {
			dimension.width = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
			dimension.height = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0)
			loadVideo();
		}, 500 )

		const onCloseReveal = e => {
			// Fix bug that reveal force focus on button causing slick to display incorrectly when the triggerer is in a slide that's already been hidden
			const target = e.target;
			const targetID = target.id;
			Array.from( document.querySelectorAll(`[data-open="${ targetID }"]`) ).forEach( triggerer => {
				const slickWrapper = triggerer.closest('.slick-initialized');
				if ( slickWrapper !== null ) {
					const slickList = slickWrapper.querySelector('.slick-list');
					if ( slickList !== null ) {
						slickList.scroll({
							left: 0,
							top: 0,
							behavior: 'smooth'
						})
					}
				}
			})
		}

		const initComponent = () => {
			changeTriggersDataAttributes();
			loadYoutube();
			loadVideo();
			playPauseVideoEvent();
			window.addEventListener('resizeEnd', e => {
				debounceResize( );
			} )
			$( element ).on('closed.zf.reveal', e => {
				onCloseReveal( e )
			})
		}

		// Function calls here ====>
		initComponent();
	}
	// Wait for loading to initiate component
	function onDocumentReady() {
		let elements = document.querySelectorAll(selectors.self);
		for (let i = 0; i < elements.length; i++) {
			new Reveal(elements[i]);
		};

		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver(function (mutations) {
			mutations.forEach(function (mutation) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new Reveal({ element });
							});
						}
					});
				}
			})
		}).observe(document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		})
	}
	if (document.readyState !== 'loading') {
		onDocumentReady();
	} else {
		document.addEventListener('DOMContentLoaded', () => {
			onDocumentReady();
		});
	}

})()
