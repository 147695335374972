import $ from 'jquery';

import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

const PeopleModalCarousel = {
	main: '.aicb-peoplesModalCarousel',
	classes: {
		slick: '.aicb-peoplesModalCarousel-slick_wrapper',
		slick_col: '.aicb-peoplesModalCarousel-column',
		slickdots: '.aicb-peoplesModalCarousel-slick_navigation-dots',
		arrows: '.aicb-peoplesModalCarousel-slick_navigation-arrows',
	},

	slickOptions: {
		slidesPerRow: 1,
		slidesToShow: 1,
		rows: 1,
		arrows: false,
		dots: true,
		autoplay: false,
		centerMode: true,
		mobileFirst: true,
		infinite: false,
		responsive: [
			{
				breakpoint: mediaQuery.breakpoint.xlarge,
				settings: {
					centerMode: true,
					slidesPerRow: 5,
					slidesToShow: 5,
					rows: 1,
					// arrows: true,
				}
			},
			{
				breakpoint: mediaQuery.breakpoint.large,
				settings: {
					centerMode: true,
					slidesPerRow: 4,
					slidesToShow: 4,
					rows: 1,
					arrows: true,
				}
			},
			{
				breakpoint: mediaQuery.breakpoint.medium,
				settings: {
					centerMode: true,
					slidesPerRow: 2,
					slidesToShow: 2,
					rows: 1,
					// arrows: false,
				}
			},
		]
	},

	init: function() {
		this._initslick();
	},
	
	resize: function() {
	},

	_initslick: function() {
		$(`${this.main}`).each( (i, carousel) => {
			let component = $(carousel);
			let wrapper = component.find(`${this.classes.slick}`);
			let slickDots = component.find(`${this.classes.slickdots}`);
			let slickArrows = component.find(`${this.classes.arrows}`);
			
			// console.log( slickArrows )

			let slickBox = component.find(`${this.classes.slick_col}`);

			if ( ! wrapper.hasClass( 'slick-initialized' ) ) {
				
				if ( $(window).width() >= mediaQuery.breakpoint.medium ) {
					if ( slickBox.length > this.slickOptions.slidesPerRow  ) {
						wrapper.slick({
							...this.slickOptions,
							...{ 'appendDots': slickDots, 'appendArrows' : slickArrows }
						})
					}
				} else {
					wrapper.slick({
						...this.slickOptions,
						...{ 'appendDots': slickDots, 'appendArrows' : slickArrows }
					})
				}
				
				if ( isSafari.safari && $(window).width() < mediaQuery.breakpoint.medium ) {
					if ( wrapper.find($`${this.main}-column`) ) {
						setTimeout(() => {
							let height = wrapper.find(`${this.main}-column`).innerHeight();
							if ( wrapper.find(`.slick-track`) ) {
								wrapper.find(`.slick-track`).css('height', height)
							}
						}, 500);
					}
				}
			}
		} );
	},
}

export default PeopleModalCarousel;