( function(){
	const selectors = {
		self: `.page-with-sidebar-content:not([aicb-template-ready])`,
    tabList: `.aicb-qualification-membership-tab-wrapper .aicb-qualification-membership-tab`,
	}

  const PageWithSidebarContent = ( element ) => {
		element.setAttribute('aicb-template-ready', 'true'); // Prevent multiple initialisation;

    const resizeBar = () => {
      const tabList = element.querySelector( selectors.tabList );
      if ( tabList !== null ) {
        tabList.style.removeProperty('--var-tabs-width');
        window.requestAnimationFrame( () => {
          const tabListWidth = tabList.getBoundingClientRect().width;
          const tabsWidth = Array.from( tabList.querySelectorAll(':scope > li') ).reduce( ( sum, li ) => li.getBoundingClientRect().width + sum, 0 )
          tabList.style.setProperty('--var-tabs-width', `${ Math.max( tabsWidth, tabListWidth )}px`);
        })
      }
    }
 

    const initComponent = async () => {
      resizeBar();
      window.addEventListener('resizeEnd', e => {
        resizeBar();
      })
		}

		// Function calls here ====>
		initComponent();
  }

  // Wait for loading to initiate component
	function onDocumentReady(){
    let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
      // Do not initalize if it was sibling of another
      if ( elements[i].previousElementSibling === null ||  elements[i].previousElementSibling.getAttribute('data-aicb-entry-component') !== 'PageWithSidebarContent' ) {
        new PageWithSidebarContent( elements[i] );
      }
		};
			
    // Mutation Observer to observe dynamically addeded component
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    new MutationObserver( function( mutations ) {
      mutations.forEach( function ( mutation ) {
        let nodesArray = [].slice.call(mutation.addedNodes);
        if (nodesArray.length > 0) {
          nodesArray.forEach(function (addedNode) {
            if (addedNode.querySelectorAll) {
              [].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
                if ( element.previousSibling === null ||  element.previousSibling.getAttribute('data-aicb-entry-component') !== 'PageWithSidebarContent' ) {
                  new PageWithSidebarContent( element );
                }
              });
            }
          });
        }
      } )
    } ).observe( document.querySelector('body'), {
      subtree: true,
      childList: true,
      characterData: true
    } )
	}
  if (document.readyState !== 'complete') {
    onDocumentReady();
  } else {
    window.addEventListener('load', () => {
      onDocumentReady();
    });
  }


} )()