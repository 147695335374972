
import $ from 'jquery';
require('lightgallery');


// import { LuminousGallery } from 'luminous-lightbox';

import mediaQuery from '../util/aicb.mediaquery';
import 'slick-carousel';

const ImageGallery = {

	init: function() {
		this._initGallery();
	},

	resize: function() {
	},

	_initGallery: function() {
		/* var lightbox = new LuminousGallery (
			document.querySelectorAll('.eventsGallery-image-thumbnail')
		) */
		$('.aicb-imageGallery').lightGallery();


	}
}

export default ImageGallery;