import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-homeEventsAndAnnouncements:not([aicb-component-ready])`,
    parts: {
      slickWrapper: `.aicb-eventsAndAnnouncements-slickWrapper`,
      slickDots: `.aicb-eventsAndAnnouncements-slickDots`,
      slickArrows: `.aicb-eventsAndAnnouncements-slickArrows`,
			slickCols: '.aicb-event-column',
    }
	}
	const EventsAndAnnouncements = ( element ) => {
    element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const slidesToShow = {
			medium: 2,
			large: 3,
			xlarge: 3
		}
		const slickWrapper = element.querySelector( selectors.parts.slickWrapper );
		const slickCols = Array.from( element.querySelectorAll( selectors.parts.slickCols ) );
		const maxSlides = slickCols.length;
		const slickDots = element.querySelector( selectors.parts.slickDots );
		const slickArrows = element.querySelector( selectors.parts.slickArrows );

    const slickOptions = {
			slidesToShow: 1.25,
			arrows: true,
			dots: true,
			autoplay: false,
			infinite: false,
			centerMode: true,
			mobileFirst: true,
			centerPadding: 0,
			draggable: true,
			swipeToSlide: true,
			responsive: [
				{
					breakpoint: mediaQuery.breakpoint.medium,
					settings: {
						slidesToShow: slidesToShow.medium,
						swipeToSlide: false,
						slidesToScroll: slidesToShow.medium,
						centerMode: false,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.large,
					settings: {
						slidesToShow: slidesToShow.large,
						swipeToSlide: false,
						slidesToScroll: slidesToShow.large,
						centerMode: false,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.xlarge,
					settings: {
						slidesToShow: slidesToShow.xlarge,
						swipeToSlide: false,
						slidesToScroll: slidesToShow.xlarge,
						centerMode: false,
					}
				},
			]
		}

    const initSlick = () => {
			if ( slickWrapper !== null && ! slickWrapper.classList.contains( 'slick-initialized' ) ) {
				$( slickWrapper ).slick( {
					...slickOptions,
					...{ appendDots: slickDots },
					...{ appendArrows: slickArrows },
				})
			}

      window.addEventListener('resizeEnd', e => {
        if ( slickWrapper.classList.contains( 'slick-initialized' ) ) { 
          $( slickWrapper ).slick('setPosition')
        }
			} )
		}

		const initComponent = () => {
			initSlick();
		}

		// Function calls here ====>
		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new EventsAndAnnouncements( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new EventsAndAnnouncements({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()