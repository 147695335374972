const Pagination = {
	init: function() {

		if ( $('.paginate-any').length > 0 ) {

			$('.paginate-any').on('click', (e) => {
				let link = e.currentTarget

				let min = parseInt($(link).attr('data-min'), 10)
				let max = parseInt($(link).attr('data-max'), 10)
				let url = stripTrailingSlash($(link).attr('data-url')) 

				let response = prompt(`Enter a page number between ${min} and ${max} to navigate into.`, "")

				if ( response != "" && response !== null && response !== undefined ) {
					let n = parseInt(response, 10)
					n = n < min ? min : n
					n = n > max ? max : n
					if ( n != undefined && ! isNaN(n) ) {
						window.location = `${url}/${n}`
					}
				}
				
			} )
		}
		

		function stripTrailingSlash(str = '') {
			if(str.substr(-1) === '/') {
				return str.substr(0, str.length - 1);
			}
			return str;
		} 
	},
	
}
export default Pagination;