import $ from 'jquery';


( function(){
  const selectors = {
    self: `.aicb-form-container:not([aicb-component-ready])`,
    allFields: `.form-body input, .form-body textarea, .form-body select`,
    uploadFields: `.form-body input[type="file"]`,
    checkboxesAndRadioesLabel: `input[type="checkbox"] + label, input[type="radio"] + label`,
    submit: `button[type="submit"]`,
    recaptchaWrapper: `.g-recaptcha-wrapper`,
  }

  const waitForLoad = (  callback ) => {
    const loop = ( callbackLoop ) => {
      if ( typeof window.grecaptcha.render !== 'undefined' ) {
        callbackLoop();
      } else {
        setTimeout( () => {
          loop( callbackLoop );
        }, 100 );
      }
    };
    loop( callback );
  };
  
  
  function FormContainer ( element ) {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
    const allFields = Array.from( element.querySelectorAll( selectors.allFields ) );
    const submitButton = element.querySelector( selectors.submit );
    let grecaptcha;

    const validateFields = ( field, doError = false ) => {
      return new Promise ( ( resolve, reject ) => {
        const formGroup = field.closest('.form-group');
        const valid = field.checkValidity();
        if ( doError ) {
          if ( valid ) {
            if ( formGroup !== null ) {
              formGroup.classList.remove('error')
            }
          } else {
            if ( formGroup !== null ) {
              formGroup.classList.add('error')
            }
          }
        }
        // console.log(`validateFields`, valid )
        resolve( valid )
      } )
    }

    const getRecaptcha = () => {
      return new Promise ( ( resolve, reject ) => {
        const response = window.grecaptcha.getResponse( grecaptcha );
        resolve( response.length > 0 );
      })
    }

    const enableDisableSubmit = ( valid ) => {
      // console.log( valid )
      if ( valid ) {
        submitButton.removeAttribute('disabled' )
      } else {
        submitButton.setAttribute('disabled', '' )
      }
    }


    const validateForms =  ( doError = false ) => {

      const validateAll = ( field ) => {
        return new Promise( resolve, reject )
      }

      return new Promise( async (resolve, reject) => {

        let allGood = true;
        allFields.forEach( async field => {
          if ( field.hasAttribute('required') ) {
            const validedField = await validateFields( field, doError );
            if ( validedField === false ) {
              allGood = false;
            }
          }
        })

        const recaptchaGood = await getRecaptcha();
        resolve( allGood && recaptchaGood );
      })
    }

    const handleFields = () => {
      const handle = async (e, field ) => {
        const valid = await validateFields( field, true )
        if ( valid ) {
          await validateForms( false ).then( ( allValid ) => { enableDisableSubmit( allValid )} );
        }
      }
      allFields.forEach( ( field ) => {
        field.addEventListener('input', (e) => { handle(e, field ) } )
        field.addEventListener('blur', (e) => { handle(e, field ) } )
      })
    }


    const handleRadioAndCheckboxesLabelSelection = () => {
      const checkboxesAndRadioesLabel = Array.from( element.querySelectorAll( selectors.checkboxesAndRadioesLabel ) )
      checkboxesAndRadioesLabel.forEach( label => {
        label.addEventListener('click', async e => {
          // console.log( e.currentTarget );
          const siblingInput = e.currentTarget.previousElementSibling;
          if ( siblingInput !== null ) {
            siblingInput.checked = siblingInput.checked ? false : true;

            const valid = await validateFields( siblingInput, true )
            if ( valid ) {
              const allValid = await validateForms( false );
              enableDisableSubmit( allValid );
            }
          }
        })
      })
    }

    const makeRecaptcha = () => {
      const recaptchaWrapper = element.querySelector( selectors.recaptchaWrapper );
      if ( recaptchaWrapper !== null ) {
        const siteKey = recaptchaWrapper.getAttribute('data-site-key');
        grecaptcha = window.grecaptcha.render( recaptchaWrapper, {
          siteKey,
          callback: async () => {
            const allValid = await validateForms( true );
            enableDisableSubmit( allValid );
          }
        } )
      }
    }

    async function submitForm ( event ) {
      if ( element.classList.contains('submitting') ) {
        return;
      }
      // Prevent the form from actually submitting
      event.preventDefault();
      element.classList.add('submitting');
      enableDisableSubmit( false );
      var data = $(this).serialize();
      // Send it to the server
      $.post('/wheelform/message/send',
      data,
      function(response) {
          if (response.success) {
            //reponse.message is the message saved in the Form Settings
            $('#modal-aicb-reveal-contact').foundation('close');
            $('#modal-aicb-reveal-contact-success-response').foundation('open');
          } else {
            // console.log( response.values )
            // console.log( response.errors )
            // response.values will contain user submitted values
            // response.errors will be an array containing any validation errors that occurred, indexed by field name
            // e.g. response.error['email'] => ['Email is required', 'Email is invalid']
            alert('An error occurred. Please try again.');
            
            $('#modal-aicb-reveal-contact').foundation('close');
            $('#modal-aicb-reveal-contact-failed-response').foundation('open');
          }
          element.reset();
          window.grecaptcha.reset();
          element.classList.remove('submitting');
      });
    }

    const initComponent = async () => {
      setTimeout(() => {
        makeRecaptcha();
        handleRadioAndCheckboxesLabelSelection();
        handleFields();
        element.addEventListener( 'submit', submitForm )
      }, 500 )
		}

		// Function calls here ====>
    waitForLoad(() => {
      initComponent();
    })
  }


  // Wait for loading to initiate component
  function onDocumentReady(){
    let elements = document.querySelectorAll(selectors.self);
    for ( let i = 0; i < elements.length; i++ ) {
      new FormContainer( elements[i] );
    };
      
    // Mutation Observer to observe dynamically addeded component
    const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
    new MutationObserver( function( mutations ) {
      mutations.forEach( function ( mutation ) {
        let nodesArray = [].slice.call(mutation.addedNodes);
        if (nodesArray.length > 0) {
          nodesArray.forEach(function (addedNode) {
            if (addedNode.querySelectorAll) {
              [].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
                new FormContainer({ element });
              });
            }
          });
        }
      } )
    } ).observe( document.querySelector('body'), {
      subtree: true,
      childList: true,
      characterData: true
    } )
  }
  if (document.readyState === 'complete') {
    onDocumentReady();
  } else {
    window.addEventListener('load', () => {
      onDocumentReady();
    });
  }
})()