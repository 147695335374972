import $ from 'jquery';
import { createPopper } from '@popperjs/core';

import mediaQuery from '../util/aicb.mediaquery';
const breakpoint = mediaQuery.breakpoint;

const MapMarkerMalaysia = {
	main: '.aicb-mapMarkerMalaysia',
	mapGrid: {
		x: 60,
		y: 25,
		locations: {
			'kualaLumpur': {
				x: 8,
				y: 15,
			},
			'labuan': {
				x: 46,
				y: 8,
			},
			'putrajaya': {
				x: 8, 
				y: 16,
			},
			'johor': {
				x: 16, 
				y: 21,
			},
			'kedah': {
				x: 4,
				y: 5,
			},
			'kelantan': {
				x: 10,
				y: 6,
			},
			'malacca': {
				x: 10,
				y: 19,
			},
			'negeriSembilan': {
				x: 11,
				y: 17,
			},
			'pahang': {
				x: 12,
				y: 13,
			},
			'perak': {
				x: 10,
				y: 10,
			},
			'perlis': {
				x: 3,
				y: 3,
			},
			'penang': {
				x: 3,
				y: 7,
			},
			'sabah': {
				x: 52,
				y: 5,
			},
			'sarawak': {
				x: 27,
				y: 23,
			},
			'selangor': {
				x: 7,
				y: 14,
			},
			'terengganu': {
				x: 14,
				y: 9,
			},
		},
	},

	init: function() {
		this._initMap();
	},
	
	resize: function() {
		$(`${this.main}`).each( (i, mapMarkerMalaysia) => {
			let component = $(mapMarkerMalaysia);
			let wrapper = component.find(`.aicb-mapMarkerMalaysia-map_wrapper`);
			let id = wrapper.attr(`data-map-id`);
			let markerWrapper = component.find(`.aicb-mapMarkerMalaysia-map_markers`);
			let map_accordion = component.find(`.aicb-mapMarkerMalaysia-loop`);
			let map = {
				map: $(`svg#Malaysia-${ id }`),
				width: $(`svg#Malaysia-${ id }`).outerWidth(),
				height: $(`svg#Malaysia-${ id }`).outerHeight(),
			}
			let markers = component.find( '.aicb-map-marker' );
			if ( markers.length > 0 ) {
				markers.each( (j, marker) => {
					$(marker)
					.css( 'top', `${ ( $(marker).attr('data-marker-y') - 1 ) * (map.height / this.mapGrid.y) }px` )
					.css( 'left', `${ ( $(marker).attr('data-marker-x') - 1 ) * (map.width / this.mapGrid.x) }px` )
					.css('width', `${ map.width / this.mapGrid.x }px`)
					.css('height', `${ map.height / this.mapGrid.y }px`);
				} );
			}
		} );
	},

	_initMap: function() {
		let _this = this;
		$(`${this.main}`).each( (i, mapMarkerMalaysia) => {
			let component = $(mapMarkerMalaysia);
			let wrapper = component.find(`.aicb-mapMarkerMalaysia-map_wrapper`);
			let id = wrapper.attr(`data-map-id`);
			let markerWrapper = component.find(`.aicb-mapMarkerMalaysia-map_markers`);
			let map_accordion = component.find(`.aicb-mapMarkerMalaysia-loop`);
			let map = {
				map: $(`svg#Malaysia-${ id }`),
				width: $(`svg#Malaysia-${ id }`).outerWidth(),
				height: $(`svg#Malaysia-${ id }`).outerHeight(),
			}
			map_accordion.each( (j, accordion) => {
				let label = $(accordion).attr(`data-marker-label`);
				let data = {
					x : $(accordion).attr(`data-marker-x`),
					y : $(accordion).attr(`data-marker-y`),
					state: $(accordion).attr(`data-marker-state`),
				}
				component.find('.aicb-mapMarkerMalaysia-map').addClass( data.state );  
				let result = { x: 0, y: 0 };
				if ( ! (data.x && data.y ) ) {
					let x, y;
					if ( this.mapGrid.locations[`${data.state}`].x && this.mapGrid.locations[`${data.state}`].y ) {
						data.x = this.mapGrid.locations[`${data.state}`].x;
						data.y = this.mapGrid.locations[`${data.state}`].y;
						result.x = ( data.x - 1 ) * (map.width / this.mapGrid.x);
						result.y = ( data.y - 1 ) * (map.height / this.mapGrid.y);
					} else {
						console.warn(`Mapgrid doesn\'t contain: ${ label }!`);
					}
				} else {
					result.x = (data.x - 1) * (map.width / this.mapGrid.x);
					result.y = (data.y - 1) * (map.height / this.mapGrid.y);
				}
				let marker = $(`<button></button>`)
					.addClass(`aicb-map-marker aicb-map-marker-${ id }`)
					.attr('data-map-label', `${ label }`)
					.attr('data-map-id', $(wrapper).attr('data-map-id') )
					.attr('data-marker-id', `${ accordion.id }`)
					.attr('data-marker-x', `${ data.x }`)
					.attr('data-marker-y', `${ data.y }`)
					.css('top', `${ result.y }px`)
					.css('left', `${ result.x }px`)
					.css('width', `${ map.width / this.mapGrid.x }px`)
					.css('height', `${ map.height / this.mapGrid.y }px`)
					.appendTo($(markerWrapper)[0]);
			} );

			component.on('mouseover', '.aicb-map-marker', (e) => {
				this._hoverEvent( component, e );
			} );

			component.on('click', '.aicb-map-marker', (e) => {
				this._clickEvent( component, e );
			} );

		})
	},

	_hoverEvent: function(component, e) {
		if ( $(window).width() < mediaQuery.breakpoint.large ) return;
		let target = $(`#${ $(e.target).attr('data-marker-id') }`);
		let box = $(`#map-box-${ $(e.target).attr('data-map-id') }-content`);
		let wrapper = $(`#map-wrapper-${ $(e.target).attr('data-map-id') }`);
		let boxContent = $('<div></div>')
			.addClass('aicb-redactorContent')
			.addClass('aicb-redactor-primary')
			.append(`<h3>${ $(e.target).attr('data-map-label') }</h3>`)
			.append(`${ $(target).find('.aicb-redactorContent').html() }`);
			$(box).html(boxContent);
		createPopper(e.target, $(box)[0], {
			placement: 'right-start',
			modifiers: [
				{
					name: 'preventOverflow',
					options: {
						altAxis: true,
						boundary: $(wrapper)[0],
					}
				},
				{
					name: 'flip',
					options: {
						fallbackPlacements: ['top', 'bottom', 'left', 'right'],
					},
				},
				{
					name: 'offset',
					options: {
						offset: [ parseInt($(e.target).innerWidth()), 0 ]
					}
				},
				{
					name: 'computeStyles',
					options: {
						adaptive: false,
					},
				},
			]
		});
		
		
	},

	_clickEvent: function(component, e) {
		if ( $(window).width() >= mediaQuery.breakpoint.large ) return;
		let accordion = $(`#map-accordion-${ $(e.target).attr('data-map-id') }`);
		let target = $(`#${ $(e.target).attr('data-marker-id')} .accordion-title`);
		$(target).get(0).click();
	},

}

export default MapMarkerMalaysia;