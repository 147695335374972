import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `.aicb-splitBanner2:not([aicb-component-ready])`,
		parts: {
			slickWrapper: `.aicb-splitBanner2-slick_wrapper`,
			slickDots: '.aicb-splitBanner2-slick_navigation-dots',
			arrows: '.aicb-splitBanner2-slick_navigation-arrows',
		}
	}

	const SplitBanner2 = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
		const slidesToShow = {
			medium: 2,
			large: 3,
			xlarge: 4
		}
		const slickWrapper = element.querySelector( selectors.parts.slickWrapper );
		const slickDots = element.querySelector( selectors.parts.slickDots );
		const arrows = element.querySelector( selectors.parts.arrows );
		const maxSlides = Array.from( slickWrapper.querySelectorAll(':scope > *') ).length;
		

		const slickOptions = {
			slidesToShow: 1,
			swipeToSlide: true,
			arrows: true,
			dots: true,
			autoplay: false,
			infinite: false,
			centerMode: true,
			mobileFirst: true,
			centerPadding: 0,
			responsive: [
				{
					breakpoint: mediaQuery.breakpoint.medium,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.medium ),
						swipeToSlide: false,
						slidesToScroll: Math.min( maxSlides, slidesToShow.medium ),
						centerMode: maxSlides < slidesToShow.medium ? true : false,
						arrows: true,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.large,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.large ),
						swipeToSlide: false,
						slidesToScroll: Math.min( maxSlides, slidesToShow.large ),
						centerMode: maxSlides < slidesToShow.medium ? true : false,
						arrows: true,
					}
				},
				{
					breakpoint: mediaQuery.breakpoint.xlarge,
					settings: {
						slidesToShow: Math.min( maxSlides, slidesToShow.xlarge ),
						swipeToSlide: false,
						slidesToScroll: Math.min( maxSlides, slidesToShow.xlarge ),
						centerMode: maxSlides < slidesToShow.xlarge ? true : false,
						arrows: true,
					}
				},
			]
		}

		const initSlick = () => {
			$( slickWrapper ).slick( {
				...slickOptions,
				...{ appendArrows: arrows, appendDots: slickDots },
			})
		}

		const initComponent = () => {
			initSlick();
		}


		// Function calls here ====>
		initComponent();
	}

	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new SplitBanner2( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new SplitBanner2({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )

		checkConstitution()
	}

	function checkConstitution() {
		// Get the hash value from the URL
		let hashValue = window.location.hash;

		if (hashValue != "") {
			// Find all elements with the class "title" and get their inner text
			let titles = document.querySelectorAll('.slick-track .aicb-splitBanner2-title h1');
				
			// Loop through the titles
			titles.forEach(function(title) {
				// Check if the inner text of the title matches 'Constitution'
				if (title.innerHTML.trim().toLowerCase().includes('constitution') || title.innerHTML.toLowerCase().includes('bye-laws')) {
					// Get the ID of the parent slick-slide
					let parentSlideId = title.closest('.slick-slide').id;
					let slideIndexStr = parentSlideId.substring(parentSlideId.length - 2)
					let slideIndex = parseInt(slideIndexStr)
					$('.aicb-splitBanner2-slick_wrapper').slick('slickGoTo', slideIndex)
				}
			});
		}
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()