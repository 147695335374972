'use strict';

import mediaQuery from './util/aicb.mediaquery';

const SearchPopup = {
	init: function() {
		let popup = $('#popup-search-wrapper');
		let overlay = $('#search-popup-overlay');
		let searchIcon = $('.popup-search-icon-button');

		searchIcon.on( 'click', (e) => {
			let position = { 
				top : $(e.currentTarget).position().top,
				right : $(window).outerWidth() - $(e.currentTarget).offset().left - $(e.currentTarget).innerWidth(),
			}
			popup.css( 'top', `${position.top}px` );
			popup.css( 'right', `${position.right}px` );
			popup.addClass( 'appear' );

			overlay.addClass('appear');
			$('#popup-search-wrapper input').get(0).focus();
		});

		overlay.on( 'click', (e) => {
			if ( overlay.hasClass('appear') ) {
				popup.removeClass( 'appear' );
				overlay.removeClass( 'appear' );
			}
		})
	},
}
export default SearchPopup;