import $ from 'jquery';

import mediaQuery from '../util/aicb.mediaquery';
import isSafari from '../util/aicb.safari';
import 'slick-carousel';

const Announcements = {
	main: '.aicb-homeAnnouncements',
	classes: {
		slick: '.aicb-announcements-row',
		slickdots: '.aicb-announcements-slick_navigation-dots',
	},

	slickOptions: {
		slidesToShow: 1,
		centerMode: true,
		arrows: false,
		dots: true,
		autoplay: false,
	},

	init: function() {
	},
	
	resize: function() {
		this._initslick();
	},

	_initslick: function() {
		if ( $(window).width() < mediaQuery.breakpoint.medium ) {
			$(`${this.main}`).each( (i, announcements) => {
				let block = $(announcements);
				let wrapper = block.find(`${this.classes.slick}`);
				let slickDots = block.find(`${this.classes.slickdots}`);
				if ( ! wrapper.hasClass( 'slick-initialized' )) {
					wrapper.slick({
						...this.slickOptions,
						...{ 'appendDots': slickDots }
					})
					if ( isSafari.safari && $(window).width() < mediaQuery.breakpoint.medium ) {
						if ( wrapper.find($`.aicb-announcements-column`) ) {
							setTimeout(() => {
								let height = wrapper.find(`.aicb-announcements-column`).innerHeight();
								if ( wrapper.find(`.slick-track`) ) {
									wrapper.find(`.slick-track`).css('height', height)
								}
							}, 500);
						}
					}
				}
			} );
		} else {
			$(`${this.main}`).each( (i, announcements) => {
				let block = $(announcements);
				let wrapper = block.find(`${this.classes.slick}`);
				if ( wrapper.hasClass( 'slick-initialized' )) {
					wrapper.slick('unslick');
				}
			} );
		}
	},
}

export default Announcements;