'use strict';

import $ from 'jquery';

var mediaQuery = {

	breakpoint: {
		small: 0,
		medium: 720,
		large: 960,
		xlarge: 1350,
	},

	resize: function() {
		if( $( '.foundation-mq' ) ) {
			let mqstyle = $('.foundation-mq').css('font-family');
			if ( typeof mqstyle !== 'string' ) {
				return breakpoint;
			}
			mqstyle.split('&').map( (param, index) => {
				let parts = param.replace(/[\+'"]/g, '').split('=');
				let key, value;
				key = parts[0];
				if( parts.length > 1 ) {
					if ( parts[1].includes('em') || parts[1].includes('rem') ) {
						value = parseFloat(parts[1]) * parseFloat(getComputedStyle(document.documentElement).fontSize);
					} else {
						value = parseFloat(parts[1]);
					}
				}	
				this.breakpoint[`${key}`] = value;
			} );
		}
	}
}

export default mediaQuery;