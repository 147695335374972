import $ from 'jquery';
import mediaQuery from '../util/aicb.mediaquery';
import 'slick-carousel';

( function(){
	const selectors = {
		self: `[data-back-to-top]:not([aicb-component-ready])`,
	}
	const BackToTop = ( element ) => {
		element.setAttribute('aicb-component-ready', 'true'); // Prevent multiple initialisation;
    const scrollTopBreakpoint = {
      mobile: 250,
      desktop: 300,
    }

		const initComponent = () => {
      element.addEventListener('click', e => {
        window.scrollTo({
          top: 0,
          left: 0,
          behavior: 'smooth'
        });
      })
      const floatingWidget = element.closest('.aicb-floating-widgets-wrapper');
      if ( floatingWidget !== null ) {
        floatingWidget.classList.add('haveBackToTop');
        window.addEventListener('scroll', e => {
          const scrollTop = document.body.scrollTop || document.documentElement.scrollTop;
          const viewportWidth = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0)
          if ( viewportWidth <=  mediaQuery.breakpoint.medium &&scrollTop >= scrollTopBreakpoint.mobile ) {
            floatingWidget.classList.remove('hideBackToTop');
          } else if (scrollTop >= scrollTopBreakpoint.desktop ) {
            floatingWidget.classList.remove('hideBackToTop');
          } else {
            floatingWidget.classList.add('hideBackToTop');
          }
        })
      }
		}


		// Function calls here ====>
		initComponent();
	}


	// Wait for loading to initiate component
	function onDocumentReady(){
		let elements = document.querySelectorAll(selectors.self);
		for ( let i = 0; i < elements.length; i++ ) {
			new BackToTop( elements[i] );
		};
			
		// Mutation Observer to observe dynamically addeded component
		const MutationObserver = window.MutationObserver || window.WebKitMutationObserver || window.MozMutationObserver;
		new MutationObserver( function( mutations ) {
			mutations.forEach( function ( mutation ) {
				let nodesArray = [].slice.call(mutation.addedNodes);
				if (nodesArray.length > 0) {
					nodesArray.forEach(function (addedNode) {
						if (addedNode.querySelectorAll) {
							[].slice.call(addedNode.querySelectorAll(selectors.self)).forEach(function (element) {
								new BackToTop({ element });
							});
						}
					});
				}
			} )
		} ).observe( document.querySelector('body'), {
			subtree: true,
			childList: true,
			characterData: true
		} )
	}
  if (document.readyState !== 'loading') {
    onDocumentReady();
  } else {
    document.addEventListener('DOMContentLoaded', () => {
      onDocumentReady();
    });
  }
})()