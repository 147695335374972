
$( window ).on('load resize orientationchange', function() {
	if ( this.resizing ) clearTimeout( this.resizing );
	this.resizing = setTimeout( function() {
		$(this).trigger('resizeEnd');
		window.dispatchEvent( new CustomEvent( 'resizeEnd' ) )

	}, 300 );
} );


